import React from "react";

export const YoutubeSvg = ({ ...props }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6241 5.70975C21.4841 5.94082 22.1645 6.62118 22.3956 7.48125C22.8192 9.04736 22.832 12.2951 22.832 12.2951C22.832 12.2951 22.832 15.5557 22.4084 17.109C22.1773 17.9691 21.497 18.6494 20.6369 18.8805C19.0836 19.3041 12.832 19.3041 12.832 19.3041C12.832 19.3041 6.58043 19.3041 5.02715 18.8805C4.16708 18.6494 3.48672 17.9691 3.25565 17.109C2.83203 15.5429 2.83203 12.2951 2.83203 12.2951C2.83203 12.2951 2.83203 9.04736 3.24281 7.49409C3.47388 6.63401 4.15424 5.95366 5.01432 5.72259C6.56759 5.29897 12.8192 5.28613 12.8192 5.28613C12.8192 5.28613 19.0708 5.28613 20.6241 5.70975ZM16.0156 12.2951L10.8295 15.299V9.29127L16.0156 12.2951Z"
        fill="white"
      />
    </svg>
  );
};
