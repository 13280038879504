import React, { useState } from "react";
import "./PrivacyPolicyModal.scss";
import { CloseSvg } from "../../assets/svg";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../components";
import { useNavigate } from "react-router-dom";
import { routes } from "../../configs/routeConfigs";

export const PrivacyPolicyModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const count_1 = ["1.", "1.1.", "1.1.1.", "1.1.2.", "1.1.3.", "1.2."];
  const count_2 = ["2.", "2.1."];
  const count_3 = [
    "3.",
    "3.1.",
    "3.1.1.",
    "3.1.2.",
    "3.1.3.",
    "3.1.4.",
    "3.1.5.",
  ];
  const count_4 = ["4.", "4.1.", "4.2."];
  const count_5 = [
    "5.",
    "5.1.",
    "5.1.1.",
    "5.1.2.",
    "5.1.3.",
    "5.1.4.",
    "5.1.5.",
  ];
  const count_6 = ["6.", "6.1.", "6.2.", "6.3."];
  const count_7 = [
    "7.",
    "7.1.",
    "7.2.",
    "7.2.1.",
    "7.2.2.",
    "7.2.3.",
    "7.2.4.",
    "7.2.5.",
    "7.2.6.",
    "7.2.7.",
    "7.2.8.",
    "7.2.9.",
    "7.2.10.",
    "7.2.11.",
  ];
  const count_8 = ["8.", "8.1.", "8.2."];
  const count_9 = ["9.", "9.1.", "9.2.", "9.3.", "9.4.", "9.5."];
  const count_10 = ["10.", "10.1."];

  return (
    <CustomModal
      open={modalOpen}
      className={"privacy-policy-modal"}
      title={t("wempler_privacy_policy")}
      footer={<></>}
      closeIcon={<CloseSvg />}
      onCancel={() => setModalOpen(false)}
      afterClose={() => {
        navigate(routes.home);
      }}
      maskClosable
      destroyOnClose
    >
      <p>{t("privacy_policy_intro_1")}</p>
      <p>{t("privacy_policy_intro_2")}</p>
      <div className={"custom-ordered-list"}>
        {count_1.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_1_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_1_rule_${i + 1}`)}</div>
            </div>
          );
        })}
        <div className={"divider"} />
        {count_2.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_2_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_2_rule_${i + 1}`)}</div>
            </div>
          );
        })}
        <div className={"divider"} />
        {count_3.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_3_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_3_rule_${i + 1}`)}</div>
            </div>
          );
        })}
        <div className={"divider"} />
        {count_4.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_4_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_4_rule_${i + 1}`)}</div>
            </div>
          );
        })}
        <div className={"divider"} />
        {count_5.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_5_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_5_rule_${i + 1}`)}</div>
            </div>
          );
        })}
        <div className={"divider"} />
        {count_6.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_6_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_6_rule_${i + 1}`)}</div>
            </div>
          );
        })}
        <div className={"divider"} />
        {count_7.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_7_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_7_rule_${i + 1}`)}</div>
            </div>
          );
        })}
        <div className={"divider"} />
        {count_8.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_8_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_8_rule_${i + 1}`)}</div>
            </div>
          );
        })}
        <div className={"divider"} />
        {count_9.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_9_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_9_rule_${i + 1}`)}</div>
            </div>
          );
        })}
        <div className={"divider"} />
        {count_10.map((number, i) => {
          return (
            <div
              className={"custom-ordered-list-item-wrapper"}
              key={`section_10_rule_${i + 1}`}
            >
              <div className={"custom-ordered-list-item-count"}>{number}</div>
              <div>{t(`section_10_rule_${i + 1}`)}</div>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};
