import {
  StatusTabTypes,
  TaskStatusMenu,
} from "../TaskStatusMenu/TaskStatusMenu";
import { TaskItem } from "../TaskItem/TaskItem";
import React, { useEffect, useMemo, useState } from "react";
import { useGetClientTasks } from "../../../../../service/client/useGetClientTasks";
import { TaskStatuses } from "../../../../../service/types";
import "./TasksContent.scss";

type TasksContentProps = {
  open: boolean;
};

const activeStatuses = [
  TaskStatuses.Created,
  TaskStatuses.Assigned,
  TaskStatuses.Accepted,
  TaskStatuses.InProgress,
];

const finishedStatuses = [
  TaskStatuses.Completed,
  TaskStatuses.Rejected,
  TaskStatuses.Refunded,
];

export const TasksContent: React.FC<TasksContentProps> = ({ open }) => {
  const { data: tasks, refetch: refetchTasks } = useGetClientTasks({
    enabled: false,
  });

  const [selectedStatus, setSelectedStatus] = useState<StatusTabTypes>(
    StatusTabTypes.ACTIVE,
  );

  useEffect(() => {
    if (open) {
      refetchTasks();
    }
  }, [open]);

  const activeTasks = useMemo(() => {
    if (tasks) {
      return tasks.filter((task) => activeStatuses.includes(task.taskStatus));
    }
    return tasks;
  }, [tasks]);

  const finishedTasks = useMemo(() => {
    if (tasks) {
      return tasks.filter((task) => finishedStatuses.includes(task.taskStatus));
    }
    return tasks;
  }, [tasks]);

  const tasksToDisplay =
    selectedStatus === StatusTabTypes.ACTIVE ? activeTasks : finishedTasks;

  return (
    <div className={"tasks-content-container"}>
      <TaskStatusMenu
        selectedStatus={selectedStatus}
        onStatusSelect={(status) => setSelectedStatus(status)}
      />

      <div className={"task-items-wrapper"}>
        {tasksToDisplay?.map((task) => {
          return <TaskItem key={task.id} task={task} />;
        })}
      </div>
    </div>
  );
};
