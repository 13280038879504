import React from "react";

export const ResendSvg = ({ ...props }) => {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.83 2.91992C4.33 2.76992 4.88 2.66992 5.5 2.66992C8.26 2.66992 10.5 4.90992 10.5 7.66992C10.5 10.4299 8.26 12.6699 5.5 12.6699C2.74 12.6699 0.5 10.4299 0.5 7.66992C0.5 6.63992 0.809996 5.68992 1.34 4.88992"
        stroke="#EF3653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.12109 3.06039L4.7811 1.15039"
        stroke="#EF3653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.12109 3.06055L5.0611 4.48055"
        stroke="#EF3653"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
