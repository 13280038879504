import React, { useEffect, useState } from "react";

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
    isXl: boolean;
    isLg: boolean;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
    isXl: window.innerWidth > 1260,
    isLg: window.innerWidth > 1024,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isXl: window.innerWidth > 1260,
      isLg: window.innerWidth > 1024,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return {
    windowWidth: windowSize.width,
    windowSize: windowSize.height,
    isXl: windowSize.isXl,
    isLg: windowSize.isLg,
  };
};
