import { useTranslation } from "react-i18next";
import { CustomButton, CustomInput, ValidationText } from "../../../components";
import React from "react";
import { Form, FormInstance, message } from "antd";
import "./ContactInfoStep.scss";
import {
  ClientUniqueCheckResp,
  useClientUniqueCheck,
} from "../../../service/client/useClientUniqueCheck";
import {
  Hash,
  SendOtpCodeResp,
  useSendOtpCode,
} from "../../../service/otp/useSendOtpCode";
import { AuthSteps } from "../AuthorizationModal";

type ContactInfoStepProps = {
  form: FormInstance;
  setAuthorizationStep: (step: AuthSteps) => void;
  phoneNumber?: string;
  setHash: (hash: Hash) => void;
};

export const ContactInfoStep: React.FC<ContactInfoStepProps> = ({
  form,
  setAuthorizationStep,
  phoneNumber,
  setHash,
}) => {
  const { t } = useTranslation();
  const { mutate: clientUniqueCheck, isLoading: clientUniqueCheckLoading } =
    useClientUniqueCheck();

  const { mutate: sendOtp, isLoading: sendOtpLoading } = useSendOtpCode();

  const onSendOtpSuccess = (data: SendOtpCodeResp) => {
    setHash(data.data.hash);
    setAuthorizationStep(AuthSteps.OTP);
  };

  const onSendOtpError = () => {
    message.error(t("error_occurred"));
  };

  const onClientUniqueCheckSuccess = (
    data: ClientUniqueCheckResp,
    phoneWithPrefix: string, //with 995
  ) => {
    const isPhoneNumberUnique = data.data.isPhoneNumberUnique;

    if (isPhoneNumberUnique) {
      //needs registration, redirect to personal info step
      setAuthorizationStep(AuthSteps.PERSONAL_INFO);
    } else {
      //does not need registration, redirect to otp
      sendOtp(
        {
          phoneNumber: phoneWithPrefix,
        },
        {
          onSuccess: onSendOtpSuccess,
          onError: onSendOtpError,
        },
      );
    }
  };

  const onContactInfoStepNext = () => {
    const phone = form.getFieldValue("phone") || phoneNumber;
    if (phone) {
      const phoneWithPrefix = `995${phone}`;
      clientUniqueCheck(
        {
          phoneNumber: phoneWithPrefix,
        },
        {
          onSuccess: (data: ClientUniqueCheckResp) =>
            onClientUniqueCheckSuccess(data, phoneWithPrefix),
        },
      );
    }
  };

  return (
    <>
      <div className={"auth-step-body-container"}>
        <div className={"auth-step-body auth-contact-info-step-wrapper"}>
          <div className={"personal-info-step-greeting"}>{`${t(
            "greeting",
          )}! 👋🏼`}</div>
          <div className={"contact-info-step-description-text"}>
            {t("input_contact_info_to_activate")}
          </div>
          <Form.Item
            name={"phone"}
            // validateTrigger={[]}
            rules={[
              {
                required: true,
                message: <ValidationText message={t("field_is_required")} />,
              },
              {
                pattern: /^5[0-9]{8}/,
                message: <ValidationText message={t("incorrect_number")} />,
              },
            ]}
          >
            <CustomInput
              prefix={<div className={"phone-prefix"}>+995</div>}
              id="gtm-authorization-contact-info-phone-input"
              className="auth-contact-info-step-input gtm-authorization-contact-info-phone-input"
              type={"number"}
              onChange={(e: any) => {
                if (e.target.value?.length > 9) {
                  form.setFieldsValue({ phone: e.target.value.slice(0, 9) });
                }
              }}
            />
          </Form.Item>
        </div>
      </div>
      <div className={"auth-footer-button-wrapper"}>
        <CustomButton
          onClick={() => {
            form.validateFields().then(onContactInfoStepNext);
          }}
          loading={clientUniqueCheckLoading || sendOtpLoading}
          id={"gtm-authorization-contact-info-next-button"}
          className={"gtm-authorization-contact-info-next-button"}
        >
          {t("agree")}
        </CustomButton>
      </div>
    </>
  );
};
