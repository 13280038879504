import React from "react";

export const AnnouncementSvg = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="32" cy="32" r="32" fill="#EF3653" />
      <path
        d="M21.3346 34.6665L23.4342 43.0646C23.4932 43.3008 23.5227 43.4189 23.5577 43.522C23.8989 44.5294 24.806 45.2376 25.866 45.3243C25.9745 45.3332 26.0962 45.3332 26.3397 45.3332C26.6446 45.3332 26.797 45.3332 26.9254 45.3208C28.1946 45.1977 29.1991 44.1932 29.3222 42.924C29.3346 42.7956 29.3346 42.6431 29.3346 42.3383V23.3332M40.668 33.9999C43.2453 33.9999 45.3346 31.9105 45.3346 29.3332C45.3346 26.7559 43.2453 24.6665 40.668 24.6665M29.668 23.3332H24.668C21.3543 23.3332 18.668 26.0195 18.668 29.3332C18.668 32.6469 21.3543 35.3332 24.668 35.3332H29.668C32.0232 35.3332 34.9043 36.5957 37.127 37.8074C38.4237 38.5143 39.0721 38.8677 39.4967 38.8157C39.8905 38.7675 40.1882 38.5907 40.4191 38.268C40.668 37.9201 40.668 37.2239 40.668 35.8315V22.8349C40.668 21.4425 40.668 20.7463 40.4191 20.3984C40.1882 20.0758 39.8905 19.8989 39.4967 19.8507C39.0721 19.7987 38.4237 20.1521 37.127 20.859C34.9043 22.0707 32.0232 23.3332 29.668 23.3332Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
