import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleSvg } from "../../../assets/svg";
import { CustomButton } from "../../../components";
import Lottie from "lottie-react";
import Success from "../../../assets/json/Success.json";
import {
  PaymentStatus,
  usePaymentParams,
} from "../../../hooks/usePaymentParams";
import { ErrorSvg } from "../../../assets/svg/ErrorSvg";

interface IFinalStep {
  handleNext: () => void;
}

enum FinalStepMode {
  TASK_SUCCESS = "task_success",
  PAYMENT_SUCCESS = "payment_success",
  PAYMENT_FAIL = "payment_fail",
}

export const FinalStep: React.FC<IFinalStep> = ({ handleNext }) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<FinalStepMode>(FinalStepMode.TASK_SUCCESS);
  const { paymentStatus, removePaymentStatus } = usePaymentParams();

  useEffect(() => {
    if (paymentStatus) {
      if (paymentStatus === PaymentStatus.SUCCESS) {
        setMode(FinalStepMode.PAYMENT_SUCCESS);
      } else if (paymentStatus === PaymentStatus.FAIL) {
        setMode(FinalStepMode.PAYMENT_FAIL);
      }
    } else {
      setMode(FinalStepMode.TASK_SUCCESS);
    }
  }, [paymentStatus]);

  const getText = () => {
    switch (mode) {
      case FinalStepMode.PAYMENT_SUCCESS:
        return (
          <div className={"success-step-text"}>{t("successful_payment")}</div>
        );
      case FinalStepMode.PAYMENT_FAIL:
        return <div className={"error-step-text"}>{t("failed_payment")}</div>;
      default:
        return <div className={"success-step-text"}>{t("task_submitted")}</div>;
    }
  };

  return (
    <>
      <div className={"success-step-wrapper main-input-wrapper"}>
        {mode === FinalStepMode.PAYMENT_FAIL ? (
          <ErrorSvg />
        ) : (
          <Lottie
            animationData={Success}
            loop={false}
            style={{ height: "100px" }}
          />
        )}
        {getText()}
      </div>
      <CustomButton
        id={"gtm-task-flow-finish-button"}
        className={"success-step-button gtm-task-flow-finish-button"}
        suffixIcon={<CheckCircleSvg />}
        onClick={() => {
          removePaymentStatus();
          handleNext();
        }}
      >
        {t("finish")}
      </CustomButton>
    </>
  );
};
