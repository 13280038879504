import React from "react";
import "./CustomInput.scss";
import { Input, InputProps } from "antd";

type CustomInputProps = InputProps & {
  className?: string;
};

export const CustomInput: React.FC<CustomInputProps> = ({
  className,
  ...props
}) => {
  return (
    <Input
      className={`custom-input ${className ?? ""}`}
      onWheel={(e: any) => e.target.blur()}
      {...props}
    />
  );
};
