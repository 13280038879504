import React from "react";
import "./ReviewItem.scss";

interface IReviewItem {
  image: string;
  reviewText: string;
  reviewerName: string;
  reviewerInfo: string;
  color: string;
  alignSelf?: string;
}

export const ReviewItem: React.FC<IReviewItem> = ({
  image,
  reviewText,
  reviewerName,
  reviewerInfo,
  color,
  alignSelf,
}) => {
  return (
    <div
      data-aos="zoom-in"
      data-aos-offset="2"
      className={"review-item-container"}
      style={{ alignSelf }}
    >
      <img src={image} className="review-image" alt={""} />
      <div className={"review-text-wrapper"}>
        <div className={"reviewer-data"} style={{ color }}>
          <span className={"reviewer-name"}>{reviewerName}</span>
          <span className={"reviewer-info"}>{reviewerInfo}</span>
        </div>
        <div className={"review-text"}>{reviewText}</div>
      </div>
    </div>
  );
};
