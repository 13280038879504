import { apiClient } from "../apiClient";
import { useQuery } from "@tanstack/react-query";
import { GeneralResponseType } from "../types";

export type PromotionAlertData = {
  id: string;
  title: string;
  description: string;
  bannerImage: string;
  companyImage: string;
  backgroundColorHex: string;
  buttonActionType: number;
  buttonActionKey: string;
};

type GetPromotionAlertsResp = GeneralResponseType<PromotionAlertData[]>;

export const getPromotionAlerts = (): Promise<GetPromotionAlertsResp> =>
  apiClient.get("/promotion/alerts").then((resp) => resp.data);

export const useGetPromotionAlerts = () => {
  const { data, isLoading } = useQuery(
    ["promotion-alerts"],
    getPromotionAlerts,
  );
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
  };
};
