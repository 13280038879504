const ENtranslations = {
  //COMMON
  field_is_required: "Field is required",
  error_text:
    "Error has occurred while processing your request. Please try again",
  incorrect_number: "Phone number is incorrect",

  //OTP
  enter_code: "Enter Code",
  code_was_sent_to: "Code was sent to {{number}}",
  send_new_code: "Send new code",
  agree: "Submit",
  incorrect_code: "Code is incorrect",
  error_occurred: "Error occurred, try again",

  //AUTHORIZATION
  authorization: "Authorization",
  input_contact_info_to_activate:
    "Please fill out the fields to activate your account",
  agree_to_wemplers: "I agree to Wempler's",
  to_terms_and_conditions: "Terms & Conditions",
  to_privacy_policy: "Privacy Policy",
  and: "and",
  greeting: "Welcome",
  completed_auth_successfully: "Completed authorization successfully",
  task_from_web: "Web task",

  //HEADER
  main_page: "Main",
  about_us: "About Us",
  how_it_works: "How it Works",
  contact: "Contact",
  contact_us: "Contact Us",
  call_us: "Call Us",
  email_us: "Email Us",
  send_task: "Send Task",
  become_doer: "Become a Doer",

  //HEADER DROPDOWN
  download_app: "Download App",
  choose_language: "Choose Language",
  soon_available: "Coming soon on",

  //HEADER USER DROPDOWN
  edit: "Edit",
  tasks: "Tasks",
  log_out: "Log Out",

  //HEADER TASKS MODAL
  active: "Active",
  finished: "Finished",
  created: "Created",
  assigned: "In Progress",
  accepted: "In Progress",
  in_progress: "In Progress",
  completed: "Completed",
  rejected: "Rejected",
  refunded: "Refunded",
  pay: "Pay",
  insured_by_prime: "Insured by Prime",

  //HEADER EDIT PROFILE MODAL
  save_changes: "Save changes",
  profile: "Profile",

  //PAYMENT MODAL
  payment_successful: "Payment went through successfully",
  payment_unsuccessful: "Unfortunately, your payment has been declined",
  payment_pending: "Your payment has not gone through yet",
  please_try_again: "Please try again",
  try_again: "Try again",
  please_check_again: "Please check again",
  check_again: "Check again",
  please_wait: "Please wait...",
  payment_verification_pending: "Payment is being verified",

  //MAIN
  describe_task: "Just tell us what you need , Wempler can do anything!",
  help_question: "Get help in minutes",
  i_need: "I Need...",
  next: "Next",
  your_contact_info: "Your contact information",
  name: "Name",
  last_name: "Last name",
  phone: "Phone",
  agree_to_terms: "I agree to Wempler's terms and conditions",
  submit: "Submit",
  task_submitted:
    "We've received your Task, a member of the Wempler team will be in touch shortly!",
  successful_payment: "Payment success",
  failed_payment: "Payment failed",
  agree_to: "By clicking next, you agree to Wempler's",
  choose_city: "Choose city",
  your_address: "Your address",
  add_info: "Do you have any information to add?",
  terms_and_conditions_adjusted: "Terms and Conditions",
  comment: "Comment",
  finish: "Finish",
  max_char_limit: "Number cannot exceed {{limit}} characters",
  get_help: "Request Help",

  //ABOUT US
  //*MAIN
  what_is_wempler: "What is Wempler?",
  description_subtext_one:
    "Wempler is a platform that will help you solve any problem. On the other hand, it gives you an opportunity to use your skills to help others and earn additional income.",
  description_subtext_two:
    "შენ გვიგზავნი დავლებას, ჩვენ თავაზობთ ადამიანს, ვინც შეასრუელებს ამ საქმეს ორივე მხარისთვის მისაღები პირობებით.",
  download_from: "Download from",
  from_store: "{{store}}",
  soon: "Coming Soon",
  on_store: "on {{store}}",

  //*CONTACT
  work_flexibly:
    "Do you have free time and want to earn money? Fill out the form and become a Doer",
  contact_subtext:
    "Work with your personalized schedule, help others with your skills and knowledge, and earn money.",
  fill_out_form: "Fill out the form",

  //*REVIEWS
  giorgi: "Giorgi",
  years: "{{age}}",
  review_one_text: "Perfect team, perfect job! thanks.",
  michael: "Michael",
  nikusha: "Nikusha",
  natia: "Natia",
  review_two_text:
    "With the help of Wempler, I have an additional income and I am taking orders in my field.",
  anano: "Anano",
  review_three_text:
    "I needed help with shipping, I quickly found a helper and solved the problem, thanks to Wempler!",

  //*STATS
  clients: "Orderers",
  task_doers: "Helpers",
  task: "Tasks",

  //HOW IT WORKS
  how_wempler_works: "How does it work?",
  if_client: "If You need help",
  if_employee: "If You want to help other people",
  client_instruction_one:
    "Share with us what you need help with, in the application or on the website",
  client_instruction_two: "We find a person who will help you ",
  client_instruction_three: "Task Is Done!",
  employee_instruction_one:
    "You fill out the form on the Wempler website or application, or contact us (032) 2 022 010",
  employee_instruction_two: "Choose your desired work field and hours.",
  employee_instruction_three:
    "You are starting helping others and earning money!",

  //FOOTER
  address: "Address",
  address_street: "Tbilisi, Ilia Chavchavadze 33e",
  email: "E-mail",
  if_you_have_questions:
    "If you have any questions or ideas, contact us with your preferred network",
  terms_and_conditions: "Terms and Conditions",

  //TERMS AND CONDITIONS MODAL
  wempler_terms_and_conditions: "Wempler's Terms and Conditions",
  terms_heading:
    "Terms used in the “Terms and Conditions” shall have the following meanings",
  section_1_left: "Customer",
  section_1_right:
    'In these "Terms and Conditions" You will be referred to as the "Customer" in these Terms of Service. "Customer" can be any natural or legal person who wants to receive short-term service/performance from the "Provider" and who places a "task" on the "Platform" for the said purpose.',
  section_2_left: "Provider",
  section_2_right:
    'A natural or legal person who wants to receive and perform an "assignment" from you. Note that the "Provider" is acting as your independent contractor when performing the "Task"',
  section_3_left: "Account",
  section_3_right: 'Your personal account registered on the "Platform"',
  section_4_left: "Registration of the Account",
  section_4_right: "Registering the “Account” on the “Platform”",
  section_5_left: "Platform",
  section_5_right:
    "Web page of Wempler LLC – www.wempler.com and mobile application of Wempler LLC",
  section_6_left: "e-mail",
  section_6_right:
    'e-mail address that you will use while "registration of the account" and use to sign in to your account on the "Platform"',
  section_7_left: "Task",
  section_7_right: "The “Task/job” assignment you place on the Platform",
  section_8_left: "Information on the Task",
  section_8_right:
    'the information posted by you on the "Platform", where you define the desired type of "task" and the details and conditions related to the performance of the "task", including the place, period and amount of the "service fee',
  section_9_left: "Service fee",
  section_9_right:
    'The Service Fee is determined by you when you post the "Task Information" on the "Platform". Also, in case of your consent, when determining the alternative price for the acceptance of the "task" by the "Provider". The "service fee" is automatically added to the "Commission fee" of "Wempler" in the amount of 20%',
  section_10_left: "Commission",
  section_10_right:
    'the amount to be paid for the use of the "Platform", the payment obligation of which arises from the moment of the start of the execution of the "task" placed by you by the "Provider',
  section_11_left: "Service Agreement",
  section_11_right:
    'A contract between you and the "Provider" that is automatically generated by the start of the "Task Execution Process',
  section_12_left: "Confirmation of task completion",
  section_12_right:
    'Confirmation of the execution of the "task" placed by you on the "Platform" by the "provider", after which the process of executing the "task" begins',
  section_13_left: "The process of completing the task",
  section_13_right:
    'The period from the moment of selection/confirmation of the "Provider" by you to the completion of the "task"',
  section_14_left: "Personal data",
  section_14_right:
    'Data you enter on the "Platform" when "creating an account". Also, personal data of the "provider", which will become known to you when the "Provider" performs the "task"',
  section_15_left: "Intellectual property of Wempler",
  section_15_right:
    "trademark, logo, domain and other brand-related features of Wempler LLC",
  section_16_left: "Rating of the Customer",
  section_16_right:
    "the coefficient obtained as a result of your assessment by you and the Provider, which determines your and the Provider’s rating, and this information is available to all Wempler users",
  section_17_left: "Operator",
  section_17_right:
    'An employee of "Wempler" who will help the "parties" in solving technical or other problems (within the scope of his/her authority)',
  section_18_left: "Parties",
  section_18_right: "The parties refer to you and “Provider” collectively",
  section_19_left: "Third party",
  section_19_right:
    'Any natural or legal person who is not a “Customer: of the "Wempler" and does not have an "Account"',

  paragraph_1:
    'The Terms and Conditions of use of Wempler Services (hereinafter "Terms and Conditions" or "Agreement") constitute a legally binding agreement between you and "Wempler" LLC (A company registered and operating under the laws of Georgia, legal address: Georgia, Tbilisi, Mtatsminda district, Tsavkisi village, Vakhtang Gorgasali dead end X, N5, identification number: 404604736) (hereinafter "Wempler") that governs using of the "Platform" by you.',
  paragraph_2:
    'While "Registration of the Account" on the "Platform", you must fully read and agree to the "Terms and Conditions". By "Registering the account" on the "Platform" you confirm that you have fully familiarized yourself with “Terms and Conditions” and agree to it.',
  paragraph_3:
    'The use of Personal Data provided by you on the Platform or that Wempler collects about you is governed by Wempler\'s privacy policy ("Privacy Policy"). A copy of Wempler\'s Privacy Policy is available on the Platform. By “Registering the Account" on the "Platform", you confirm that you fully agree to Wempler\'s "Privacy Policy."',
  paragraph_4:
    '"Wempler" reserves the right to unilaterally make changes/amendments to the "Terms and conditions" at any time, by sending you a corresponding notification via e-mail. If you continue to use the "Platform" and/or you have access to the "Platform" after the changes/amendments to the "Terms and conditions" are made, it is considered that you fully agree with the changes/amendments made by Wempler.',
  paragraph_5:
    " If any future changes/amendments to the “Terms and conditions” are unacceptable to you, you must cancel your Account and immediately stop using the “Platform”.",
  paragraph_6:
    "If you do not agree to the obligations under the “Terms and Conditions”, you will not be able to use the Platform.",

  section_heading_1: '"Wempler" service description and basic terms',

  list_item_1:
    '“Wempler" is a double-sided "platform" that connects a "customer" who is willing to receive a short-term service and a "Provider" who is willing to perform a "task". "Wempler" is not responsible for the conditions specified in the "Information on the task", as well as for the execution of the "task", does not control and supervise the execution of the "task" by the "Provider". Quality of performance, term, legality, failure. Wempler is not responsible for the competence, qualifications, or any acts or omissions of the Parties.',
  list_item_2:
    'By using the "Platform", you can express your willingness to perform a "task" posted by the "Customer", in return for which you will receive a "Service Fee" from the "Customer". (hereinafter - "Service")',
  list_item_3:
    'The "Terms and Conditions" govern your registration procedures and access to the "Platform", your use of the "Platform", the manner of execution of the "task" placed on the "Platform" by the "Customer" and the conditions for receiving the "service fee" in exchange for the "service" provided by you, as well as, other terms of use of the "Platform".',

  section_heading_2: "Representations and Warranties of the “Customer”",

  list_item_4: "You represent and warrant that:",
  list_item_4_nested_1:
    '(a)  You have full authority to agree on the "Terms and Conditions" and to fulfill the obligations defined by the "Agreement"',
  list_item_4_nested_2:
    '(b)  You have fully familiarized yourself with the "Terms and Conditions", you understand the content of the manifestation of your will, the essence of the conditions of the "Agreement" and the legal consequences arising from it;',
  list_item_4_nested_3:
    '(c)  The data and/or information provided by you is complete, accurate and true and you will fulfill the obligations defined by the "Terms and Conditions" in good faith, fully and accurately;',
  list_item_4_nested_4:
    '(d)  During the "Registration of the account" and the entire period of using the "Platform", your activities and/or actions are/will be in accordance with the requirements of the legislation of Georgia;',
  list_item_4_nested_5:
    "(e)  Your actions are/will not be aimed at defrauding “Wempler”, the “Provider” and/or any “third party” and you are not involved in and/or participating in any kind of illegal activity;",
  list_item_4_nested_6:
    '(f)  You do not consume narcotic, alcoholic, psychotropic or any other toxic drugs in the “The process of completing the task ";',
  list_item_4_nested_7: '(g)  You will protect the privacy of the "Customer";',
  list_item_5:
    "You are obliged to promptly notify Wempler in writing of any circumstance(s) that may conflict with the warranties and/or representations made by you hereunder and/or cause a breach of above mentioned warranties/representations",
  list_item_6:
    'Wempler enters into this "Agreement" solely on the basis of your representations and warranties. Representations and Warranties constitute the terms of the "Agreement", violation of which will result in suspension and/or cancellation of your Wempler "Account".',

  section_heading_3: '"Registration of the Account", Use and Security',

  subheading_1_section_3: '„Registration of the Account"',

  list_item_7:
    'In order to use the "Platform" it is necessary to "Register an account". While registration you have to provide your personal data, in particular: first name, last name, personal number, phone number, email address, and at least one valid payment method. You also have to upload to the Platform a photo of you and a copy of your ID card which allows Wempler to identify you. You agree to ensure the accuracy, completeness and up-to-dateness of the information provided by you in the event that the information provided at the time of account opening changes.',
  list_item_8:
    'If personal information provided by you is found to be false or inaccurate, you will be notified via e-mail about the detected violation and a deadline will be set for correcting the violation. Until the detected violation is corrected, Wempler is entitled to temporarily suspend your "Account". If you do not correct the violation/defect within the specified time, "Wempler" is entitled to cancel your "Account".',

  subheading_2_section_3: "Using of the “Account”",

  list_item_9:
    "You are entitled to use the “Account” only in accordance with the terms and conditions of this Agreement.",
  list_item_10:
    'In case of using your “Account” by the "Third Party", he/she/it is deemed to be acting on your behalf and has authority from you. In case of transferring your Account to a "Third Party", you are responsible for any direct or indirect damages resulting from the actions of the "Third Party".',
  list_item_11:
    'You are obligated to immediately notify Wempler of any illegal and/or unauthorized use of Personal Data. You are obliged to indemnify the damage and/or loss that may occur to the "Company", "Customer" or "Third Parties" as a result of breach of above mentioned obligation. ',

  subheading_3_section_3: "Security",

  list_item_12:
    'You are responsible for securing "Personal Data" of the “Customer”. You are not authorized to use the “personal data” of the “Customer” and/or share it with "Third Parties" (Except when data is used to perform a "task" defined by the "Platform"). Violation of this obligation leads to the cancellation of your "Account" and the compensation of the damage caused to "Wempler", "Customer" and/or "third parties" as a result of the violation of the obligation specified in this paragraph. You are fully responsible for the accuracy of the personal information provided on the Platform, its protection and all activities carried out using your Account.',
  list_item_13:
    'In order to use the "Services" of "Wempler" you must present to "Wempler" a valid card on which you can receive the "Service Fee". Only your payment method information is available to Wempler. Information on each successful payment is stored with a third-party (payment service provider) used by Wempler. You have an obligation to update the bank card and other payment-related information listed on your Account.',

  section_heading_4:
    "Initiation, confirmation and execution of the “task”, settlement",

  subheading_1_section_4: 'Placing and confirming the "Task".',

  list_item_14:
    'The "User" is entitled to post "Task Information" on the "Platform", where the details and conditions related to the execution of the "Task" will be specified, including, but not limited to, the place of the "Task" execution, the term and the "Service Fee" to be specified for the purpose of placing the "Task" The information may be changed from time to time, unilaterally by Wempler. By the posting of the “Task Information” by the “User” on the “Platform”, the “Task” is deemed to be posted. You have the opportunity to view the “Task Information” posted by the “User” on the “Platform”. You You are given the opportunity to read the "Task Information" and agree to perform the work specified in the "Task". Confirmation of your acceptance of the "Task" will be sent to the "User", who must confirm that you have started the "Task".',
  list_item_15:
    'You have the right to offer the "Customer" an alternative/modified "Service Fee". In case of confirmation by the "User", the alternative/changed "Service Fee" will be reflected in the "Task Information". In case another "Provider" agrees to perform the "task" besides you, the "user" has the right to choose the "Provider" he wants. Changes to the "Task Information" you have posted can be made through the "Operator".',

  subheading_2_section_4: "Enforcement of the “Task”",

  list_item_16:
    'The "task execution process" starts from the moment the "Provider" confirms the execution of the "task" and you select the "Provider".  ',
  list_item_17:
    'YOU ACKNOWLEDGE AND AGREE THAT BY COMMENCEMENT OF THE TASKING PROCESS, A LEGALLY BINDING AGREEMENT IS CREATED BETWEEN YOU AND THE HELPER (THE "SERVICE AGREEMENT"). The terms of the "Service Agreement" include this "Agreement", as well as the "Assignment Information" confirmed by you and other contractual terms defined by law, to the extent that such terms do not conflict with the terms of the "Agreement" and do not expand the obligations of "Wempler" or limit "Wempler" rights. "Wempler" is not a party to the "Service Agreement" and it is not allowed to enter into any kind of agreement on behalf of "Wempler".',
  list_item_18:
    "For purposes of completing a Task, a Provider is authorized to complete a Task with the assistance of another Provider, Associate, Subcontractor, or any other person who holds an Account on the Platform.",
  list_item_19:
    'You are authorized in the "Task Information" to order the "Provider" to purchase various consumables and/or any other items necessary for the execution of the "Task" ("Materials"). "Wempler" is not responsible for and does not guarantee the quality or price of "Materials" purchased.',
  list_item_20:
    'The "Parties" are authorized to communicate using the "Platform" only. In the event that the "Parties" bypass the Platform to communicate and/or perform and/or receive the services they should have received using the "Platform"',
  list_item_21:
    '"Wempler" is entitled to suspend the "User" and/or "Provider\'s" "Account" and request Compensation for any direct and/or indirect damages, including unearned income, to the "Parties". Also, "Wempler" is entitled, in case of violation of the obligation specified in this paragraph, to charge the breaching party or "parties" with a fine in the amount of three times the "service fee".',
  list_item_22:
    'After completing the "Task", you are allowed to anonymously rate the "Provider" using the "Platform", and the "Provider" is allowed to rate you. The "User Rating" resulting from the assessment is available to all persons with an "Account" on the "Platform".',

  subheading_3_section_4: "Payment",

  list_item_23:
    'You can pay the "Service Fee" by cashless payment. Blocking of "service fee" and "service commission" during non-cash payment is carried out immediately after you select "Provider". The blocked "Service Fee" will be automatically debited from the bank account specified by you on the "Platform" after the "Provider" completes the "Task".',
  list_item_24:
    'The "Service Fee" is administered by the payment service provider company. The payment service provider company will ensure the distribution of the "Service Fees" paid by you between "Provider" and "Wempler". Wempler will receive a commission of 20% of the Service Fee paid by you. The "Comission Fee" is automatically added to the "Service Fee" and will be deducted from your bank account along with the "Comission Fee".',
  list_item_25:
    "Wempler reserves the right to suspend payment/reimbursement of any Service Fee upon request by you or a Contributor, or upon notification of any potential fraud, unauthorized payment or misuse of the Platform.",

  section_heading_5: "Cancellation of the “Task”",

  subheading_1_section_5: "Cancellation of the “Task” by the “Customer”",

  list_item_26:
    'You are entitled to cancel a "Task" without paying an "Task Cancellation Fee" if the "Task Completion Confirmation" has not been performed by the "Provider".',
  list_item_27:
    'You are entitled, without paying a "Task Cancellation Fee", to cancel a "Task" within 30 minutes of the "execution process" starting, if the "Task" has not been executed by the "Provider". In this paragraph, the initiation of a "Task" means the execution of any action by the "Provider" to complete the "Task" placed by you.',
  list_item_28:
    'In case you cancel the "Task" during the "task execution process" in violation of the term specified in the previous paragraph and/or the "Provider" has started to carry out the action necessary for the "Task" execution, you are obligated to pay the "Task cancellation fee" 15% of the "service fee" in the amount of "Task cancellation fee" will be deducted from the blocked amount. "Wempler" reserves 20% of the "task cancellation fee".',
  list_item_29:
    'If you cancel the "Task" after the start of the "Task", you are obligated to pay the "Task Cancellation Fee" in double amount, if the reasonable expenses incurred by the "Provider" (the cost of transportation, the cost of purchased "Materials" and other the cost required to complete the "task") does not exceed twice the amount of the "task cancellation fee". If the reasonable expenses incurred by the "Provider" exceed twice the amount of the "Task Cancellation Fee", you shall reimburse the reasonable expenses incurred by the "Provider", which shall not exceed the "Service Fee" minus the "Task cancellation fee". "Task cancellation fee" will be deducted from the blocked amount.',
  list_item_30:
    'In the event that the "Provider" confirms the "Task" but does not complete the "Task Information" within the specified time, you are entitled to cancel the "Task" without paying the "Cancellation Fee".',
  list_item_31:
    'In the event that you cancel 3 (three) or more "tasks" within 24 (twenty-four) hours, "Wempler" has the right to temporarily restrict your access to the "Account". For the purposes of this paragraph, the limitation period shall be determined on a case-by-case basis in respect of which you receive notice of the limitation period. ',
  list_item_32:
    'Any protest or complaint arising from the actions and/or policies of "Wempler", you and/or "Provider", should be submitted to "Wempler" at the following email address: Info@wempler.com. "Wempler", within the scope of its powers, will review the received complaint within 2 (two) weeks and inform you of the position.',

  section_heading_6: "Suspension or Cancellation of Your “Account”",

  list_item_33:
    'In case of violation of any article specified in the "Agreement" by you, including "violation of Wempler\'s intellectual property rights", posting of incorrect information in the "Account", posting of illegal and/or immoral content by you, "Wampler" is entitled to a temporary suspend or terminate your Account and restrict your access to the Account.',
  list_item_34:
    '"Wempler" is entitled to set a deadline for the elimination of the violation. If you do not eliminate the violation within the specified period and/or "Wempler" considers that your action may endanger the reputation of "Wempler", "Customer" and/or "third parties", "Wempler" is entitled to cancel your "Account". You are obligated to compensate for any damages caused by the occurrence of the circumstances specified in this paragraph.',
  list_item_35:
    'In the event that "Account" is suspended and/or canceled by "Wempler" based on this paragraph, you will be deprived of the right to use a new "Account" created on your own and/or on behalf of another person. In case of revealing the mentioned fact, "Wempler" has the right to suspend and/or cancel the account of another person.',
  list_item_36:
    'In case your "Account" is not active for 1 year, "Wempler" is entitled to cancel your "Account". For purposes of this paragraph, your "Account" will be considered inactive if you do not use or access your "Account" during the period specified above..',
  list_item_37:
    "You are entitled to terminate your use of the Platform at any time and to deactivate/cancel your Account through the Platform.",

  section_heading_7:
    "Liability, Limitation of Liability and Exemption from liability",

  list_item_38:
    "You acknowledge and agree that Wempler shall not be liable for:",
  list_item_38_nested_1:
    "(a) personal or other electronic accuracy and/or accuracy posted on the Platform and/or delivered through the Platform;",
  list_item_38_nested_2:
    '(b) the threat of the "task" posted by the "Platform";',
  list_item_38_nested_3:
    '(c) on the production of the "assignment" by the "assistant", timely performance/and any other condition related to the performance of the "assignment";',
  list_item_38_nested_4:
    '(d) on the warranty and guarantee of the "materials" purchased by the "helper";',
  list_item_38_nested_5:
    "(e) for any other and/or indirect damages caused by the actions and/or omissions of you and/or the Helper;",
  list_item_39: "You represent your obligation to indemnify Wempler",
  list_item_40:
    '"Wempler" does not guarantee the continuity of the operation of the "Platform" and the absence of errors.',
  list_item_41:
    '"Wempler", "User" and "Helper" are exempted from liability if their performance of obligations is caused by force majeure. A force majeure environment does not release the "parties" from responsibility in the event of the termination of a different force majeure circumstance.',

  section_heading_8: "Final Provisions",

  list_item_42:
    "You are not authorized to use “Wempler's” trademark, logo, domain, or other brand-related features.",
  list_item_43:
    'Any annex/addition/change to the "Terms and conditions" constitutes an essential and integral part of the "Terms and conditions";',
  list_item_44:
    "Any official correspondence between you and “Wempler” must be in writing via the e-mail address specified in the Platform and/or Account;",
  list_item_45:
    'Your written notice to "Wempler" should be sent to the following e-mail address: info@wempler.com',

  //PRIVACY POLICY MODAL
  wempler_privacy_policy: "Wempler's Privacy Policy",
  privacy_policy: "Privacy Policy",
  privacy_policy_intro_1:
    "The foregoing document (hereinafter – the Policy) is a Policy of Wempler LLC (I/D  404604736) (hereinafter – the Company) which sets out the principles and mechanisms for collection, processing and protection of personal data by the Company.",
  privacy_policy_intro_2:
    "The purpose of the Policy is: 1) to protect human rights and freedoms, including privacy, while processing the personal data by the Company; 2) ensuring the security of personal data; 3) Compliance with the normative acts related to the protection of personal data, including the provisions of the Law of Georgia on Personal Data Protection.",

  section_1_rule_1: "Personal Data Protection",
  section_1_rule_2:
    "During registration in the mobile application owned by the Company, the user voluntarily transfers the following personal data to the Company (by filling in the relevant fields): ",
  section_1_rule_3: "Name and/or Surname;",
  section_1_rule_4: "Personal mobile number",
  section_1_rule_5: "Location.",
  section_1_rule_6:
    "After the transfer of personal data to the Company, in case of any changes in the above mentioned data, the customer/user shall be obliged to provide to the Company all changed data within the reasonable time period.",

  section_2_rule_1: "Purpose of Obtaining and Processing of Personal Data",
  section_2_rule_2:
    "The Company receives and processes personal data from the customers to identify customers and provide them with quality services",

  section_3_rule_1: "Principles of Personal Data Processing",
  section_3_rule_2:
    "The Company is guided by the following principles when processing personal data:",
  section_3_rule_3:
    "The personal data must be processed fairly and lawfully, without prejudice to the dignity of the data owner;",
  section_3_rule_4:
    "The data should only be processed for specific, clearly defined, legitimate purposes. No further processing of the data may be allowed for any other purpose other than the original purpose;",
  section_3_rule_5:
    "The personal data may only be processed to the extent necessary to achieve the appropriate lawful purpose. The data must be adequate and proportionate to the purpose for which they are processed;",
  section_3_rule_6:
    "The data should be accurate and correct and should be updated as necessary. Inappropriate data collected and processed without lawful ground shall be blocked, deleted or destroyed;",
  section_3_rule_7:
    "The data may be stored only for the period necessary for the purpose of data processing. After achievement of the purpose for which the data is processed, they shall be blocked, deleted or destroyed, or stored in the exclusionary form of identifying a person, unless otherwise provided by law.",

  section_4_rule_1: "Terms of Storage of Personal data",
  section_4_rule_2:
    "The Company holds personal data of customers during the period of legalrelationship between the Company and the customer.",
  section_4_rule_3:
    "Upon termination of the legal relationship between the Company and the customer, the Company shall archive personal data of the customer. In such case, the customer is entitled to require to destroy his/her personal data stored in the archive of the Company. ",

  section_5_rule_1: "Providing Information to the User",
  section_5_rule_2:
    "Upon the request of the customer, the Company is obliged to provide the customer with following information:",
  section_5_rule_3:
    "Information about the customer’s personal data protected/stored by the Company;",
  section_5_rule_4:
    "Identification of the employee responsible for data processing;",
  section_5_rule_5: "Legal bases for data processing;",
  section_5_rule_6: "The reason and purpose for data processing",
  section_5_rule_7:
    "To whom was issued personal data, the bases and purpose of the data issuance.",

  section_6_rule_1:
    "Processing of Data by Authorized Person/Authorized Employee of the Company ",
  section_6_rule_2:
    "The authorized person/authorized employee of the Company shall process the user’s/customer’s personal data only for the purposes set forth in this Policy.  Any further processing of data by the authorized person of the Company for any other purpose is forbidden. It is inadmissible for an authorized person to transfer data processing rights to any other person without the consent of the authorized representative (director) of the Company. ",
  section_6_rule_3:
    "The authorized person on data processing is obliged to take appropriate organizational and technical measures to protect the data.",
  section_6_rule_4:
    "The Company is obliged to monitor the data processing by the authorized person.",

  section_7_rule_1: "File System and File System Catalog",
  section_7_rule_2:
    "The Company ensures to construe and sort personal data according to specific criteria (file system).",
  section_7_rule_3:
    "The Company is obliged to maintain a file system directory for each file system and record the following information:",
  section_7_rule_4: "The name of file system;",
  section_7_rule_5:
    "The name and address of the company and the person authorized to process the data, the place of storage and/or processing of the data;",
  section_7_rule_6: "Legal bases for data processing;",
  section_7_rule_7: "Category of data subjects / users;",
  section_7_rule_8: "Data category in file system;",
  section_7_rule_9: "Purpose of data processing;",
  section_7_rule_10: "Term of data storage;",
  section_7_rule_11:
    "The fact and the ground of restricting the right of the data subject;",
  section_7_rule_12:
    "The recipient of the data stored in the file system and their categories;",
  section_7_rule_13:
    "Information on the transmission of the data to another Country and/or international organization and the basis for such transmission;",
  section_7_rule_14:
    "General description of the procedure for data security protection.",

  section_8_rule_1: "Security of Personal Data",
  section_8_rule_2:
    "The personal data obtained by the Company/provided by the customer to the Company is stored electronically in the system of the mobile application owned by the Company (hereinafter – the System) in which the customer is registered. ",
  section_8_rule_3:
    "Access to the personal data in the system has only the appropriate employee of the Company, who is obliged to keep confidentiality of data even after termination of his/her official authority.",

  section_9_rule_1: "Transmission of Personal Data to the Third Parties",
  section_9_rule_2:
    "The Company is entitled in order to protect its rights to transfer customer’s personal data to the third parties involved in the protection of rights of the Company, such as lawyer, expert etc.",
  section_9_rule_3:
    "The Company is entitled to transfer customer’s personal data to the contractor providing the Company with theservices to be provided by the Company to the customer. ",
  section_9_rule_4:
    "The Company may only transfer the personal data of customer to third parties to the extent necessary for the purposes of the transfer.",
  section_9_rule_5:
    "In case of any transfer of personal data by the Company to the third parties, the Company is obliged to ensure that the recipient of the personal data undertakes to protect the privacy/confidenciality of the personal data. ",
  section_9_rule_6:
    "In case of any transfer of personal data by the Company to the third parties, the Company is obliged to keep the following information: what kind of personal datawas transferred to the third parties; to whom was transferred the personal data; when and on what legal bases was personal data transferred. The mentioned information should be stored along with the customer’s/user’s personal data during their retention period (term of storage). ",

  section_10_rule_1: "Responsibility",
  section_10_rule_2:
    "In case of violation of the requirements of this Policy, on demand of authorized person, the Company shall be liable in accordance with the procedure established by the Legislation of Georgia.",

  //CANCELLATION POLICY MODAL
  cancellation_policy: "Cancellation Policy",
  task_cancellation_policy: "Task Cancellation Policy",

  cancellation_section_heading_1: "Cancellation of the “Task”",

  cancellation_subheading_1_section_1:
    "Cancellation of the “Task” by the “Customer”",

  cancellation_list_item_1:
    'You are entitled to cancel a "Task" without paying an "Task Cancellation Fee" if the "Task Completion Confirmation" has not been performed by the "Provider".',
  cancellation_list_item_2:
    'You are entitled, without paying a "Task Cancellation Fee", to cancel a "Task" within 30 minutes of the "execution process" starting, if the "Task" has not been executed by the "Provider". In this paragraph, the initiation of a "Task" means the execution of any action by the "Provider" to complete the "Task" placed by you.',
  cancellation_list_item_3:
    'In case you cancel the "Task" during the "task execution process" in violation of the term specified in the previous paragraph and/or the "Provider" has started to carry out the action necessary for the "Task" execution, you are obligated to pay the "Task cancellation fee" 15% of the "service fee" in the amount of "Task cancellation fee" will be deducted from the blocked amount. "Wempler" reserves 20% of the "task cancellation fee".',
  cancellation_list_item_4:
    'If you cancel the "Task" after the start of the "Task", you are obligated to pay the "Task Cancellation Fee" in double amount, if the reasonable expenses incurred by the "Provider" (the cost of transportation, the cost of purchased "Materials" and other the cost required to complete the "task") does not exceed twice the amount of the "task cancellation fee". If the reasonable expenses incurred by the "Provider" exceed twice the amount of the "Task Cancellation Fee", you shall reimburse the reasonable expenses incurred by the "Provider", which shall not exceed the "Service Fee" minus the "Task cancellation fee". "Task cancellation fee" will be deducted from the blocked amount.',
  cancellation_list_item_5:
    'In the event that the "Provider" confirms the "Task" but does not complete the "Task Information" within the specified time, you are entitled to cancel the "Task" without paying the "Cancellation Fee".',
  cancellation_list_item_6:
    'In the event that you cancel 3 (three) or more "tasks" within 24 (twenty-four) hours, "Wempler" has the right to temporarily restrict your access to the "Account". For the purposes of this paragraph, the limitation period shall be determined on a case-by-case basis in respect of which you receive notice of the limitation period. ',
  cancellation_list_item_7:
    'Any protest or complaint arising from the actions and/or policies of "Wempler", you and/or "Provider", should be submitted to "Wempler" at the following email address: Info@wempler.com. "Wempler", within the scope of its powers, will review the received complaint within 2 (two) weeks and inform you of the position.',

  cancellation_subheading_2_section_1: 'Cancellation of "task" by "Provider"',

  cancellation_list_item_8:
    'In the event that the "Provider" revokes his consent to perform the "Task", or does not appear at the location indicated in the "Task Information" or refuses to perform the "Task", you will receive a full refund of the blocked amount.',

  //FAQ MODAL
  faq: "F.A.Q",
  frequently_asked_questions: "Frequently Asked Questions",

  faq_question_1: "What is Wempler?",
  faq_answer_1_1:
    "Wempler is a Task Delegation Platform, where any user can request help with any type, and we will send suitable helper immediately, or within a scheduled time. The helper can be independent expert, or our specialized partner company.",
  faq_answer_1_2:
    "In Addition, people with skills and experience in different fields, can fill out our application, and after verification process, they can receive service request in their indicated fields and get paid for their services.",

  faq_question_2: "What type of tasks can be done by Wempler? ",
  faq_answer_2_1:
    "On Wempler customers can request any kind of task with any degree of personalization",
  faq_answer_2_2:
    "General categories include:  any kind of cleaning, any kind of delivery, help moving, car wash, car repairment, any kind of handyman job, home repairs, online personal assistant and even dog walking.",
  faq_answer_2_3:
    "In addition, we offer a ‘free category’ service, which means that customer can request any combination and customization for any task.",

  faq_question_3: "How much is Wempler’s commission?",
  faq_answer_3:
    "Wempler is working on commission from all successfully completed tasks. Commission range between 15%-20% depending on the category of the task.",

  faq_question_4: "Are tasks insured?",
  faq_answer_4:
    "Our Policy is that, if the service provided to the customer is not of the standards and qualities offered on the market, Wempler insures that the service will be provided or compensated free of charge in minimum waiting time.",

  faq_question_5: "How do you protect my personal information?",
  faq_answer_5:
    "In Wempler, we value personal information on both sides - on the Supply’s side, as well as customer’s side. We are regulated under General Data Protection Regulation, and ensure that any data present on any customer is not transferred to any third party.",
};

export default ENtranslations;
