import React from "react";

export const CloseModalSvg = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      {...props}
    >
      <path
        d="M21.75 7.25L7.25 21.75M7.25 7.25L21.75 21.75"
        stroke="#2C3642"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
