import { Form } from "antd";
import { CustomModal } from "../../components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AuthorizationModal.scss";
import { ContactInfoStep } from "./Steps/ContactInfoStep";
import { OTPStep } from "./Steps/OTPStep";
import { PersonalInfoStep } from "./Steps/PersonalInfoStep";
import { Hash } from "../../service/otp/useSendOtpCode";
import { ClientData } from "../../service/client/useGetClient";

type AuthorizationModalProps = {
  open: boolean;
  handleCancel: () => void;
  startStep?: number;
  phoneNumber?: string;
  hash?: Hash;
  handleUserLoginSuccess?: (
    client: ClientData,
    onTaskCreateSuccess?: () => void,
  ) => void;
};

export enum AuthSteps {
  CONTACT_INFO,
  OTP,
  PERSONAL_INFO,
}

export const AuthorizationModal: React.FC<AuthorizationModalProps> = ({
  open,
  handleCancel,
  startStep = AuthSteps.CONTACT_INFO,
  phoneNumber,
  hash: externalHash,
  handleUserLoginSuccess,
}) => {
  const { t } = useTranslation();
  const [authorizationStep, setAuthorizationStep] = useState<number>(startStep);
  const [hash, setHash] = useState<Hash>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    setAuthorizationStep(startStep);
  }, [startStep]);

  useEffect(() => {
    if (externalHash) {
      setHash(externalHash);
    }
  }, [externalHash]);

  const handleModalClose = () => {
    handleCancel();
    resetSteps();
    form.resetFields();
  };

  const getPersonalInfo = () => {
    if (form.getFieldValue("firstName")) {
      return {
        firstName: form.getFieldValue("firstName"),
        lastName: form.getFieldValue("lastName"),
        cityId: form.getFieldValue("cityId"),
      };
    }
    return undefined;
  };

  const authorizationStepsContent = [
    <ContactInfoStep
      key={AuthSteps.CONTACT_INFO}
      form={form}
      setAuthorizationStep={setAuthorizationStep}
      phoneNumber={phoneNumber}
      setHash={setHash}
    />,
    <OTPStep
      key={AuthSteps.OTP}
      phoneNumber={form.getFieldValue("phone") || phoneNumber}
      hash={hash}
      handleUserLoginSuccess={(client: ClientData) => {
        handleUserLoginSuccess
          ? handleUserLoginSuccess(client, handleModalClose)
          : handleModalClose();
      }}
      personalInfo={getPersonalInfo()}
    />,
    <PersonalInfoStep
      key={AuthSteps.PERSONAL_INFO}
      form={form}
      phoneNumber={form.getFieldValue("phone") || phoneNumber}
      setHash={setHash}
      setAuthorizationStep={setAuthorizationStep}
    />,
  ];

  const resetSteps = () => {
    setAuthorizationStep(0);
  };

  return (
    <CustomModal
      open={open}
      className={"authorization-modal"}
      title={t("authorization")}
      footer={false}
      onCancel={handleModalClose}
      maskClosable
    >
      <Form form={form}>{authorizationStepsContent[authorizationStep]}</Form>
    </CustomModal>
  );
};
