import React from "react";
import "./HowWemplerWorks.scss";
import { SectionHeading } from "../../../components";
import { ClientInstructions } from "./ClientInstructions/ClientInstructions";
import { useTranslation } from "react-i18next";

export const HowWemplerWorks = () => {
  const { t } = useTranslation();

  return (
    <div data-aos="fade-in" className={"how-wempler-works-container"}>
      <div className={"how-wempler-works-wrapper"}>
        <SectionHeading
          className="how-it-works-heading"
          title={t("how_wempler_works")}
        />
        <ClientInstructions />
      </div>
    </div>
  );
};
