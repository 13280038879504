import React from "react";

export const PhoneFilledSvg = ({ ...props }) => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="24" cy="24.7705" r="24" fill="#A0ACBA" />
      <path
        d="M26.0497 18.7705C27.0264 18.9611 27.924 19.4388 28.6277 20.1424C29.3314 20.8461 29.8091 21.7438 29.9997 22.7205M26.0497 14.7705C28.0789 14.9959 29.9713 15.9047 31.4159 17.3475C32.8606 18.7903 33.7717 20.6815 33.9997 22.7105M22.2266 26.6336C21.0251 25.432 20.0763 24.0734 19.3803 22.6237C19.3204 22.499 19.2905 22.4367 19.2675 22.3578C19.1858 22.0775 19.2445 21.7332 19.4145 21.4958C19.4623 21.429 19.5195 21.3718 19.6338 21.2575C19.9834 20.9079 20.1582 20.7331 20.2725 20.5573C20.7035 19.8944 20.7035 19.0398 20.2725 18.3769C20.1582 18.2012 19.9834 18.0264 19.6338 17.6768L19.4389 17.4819C18.9075 16.9504 18.6417 16.6847 18.3564 16.5404C17.7888 16.2533 17.1185 16.2533 16.551 16.5404C16.2656 16.6847 15.9999 16.9504 15.4684 17.4819L15.3108 17.6395C14.7812 18.1691 14.5164 18.434 14.3141 18.794C14.0897 19.1935 13.9283 19.814 13.9297 20.2722C13.9309 20.6851 14.011 20.9674 14.1712 21.5318C15.0322 24.5652 16.6567 27.4276 19.0447 29.8156C21.4326 32.2035 24.295 33.828 27.3284 34.689C27.8928 34.8492 28.1751 34.9293 28.588 34.9305C29.0462 34.9319 29.6667 34.7705 30.0662 34.5461C30.4263 34.3439 30.6911 34.079 31.2207 33.5494L31.3783 33.3918C31.9098 32.8603 32.1755 32.5946 32.3198 32.3092C32.6069 31.7417 32.6069 31.0714 32.3198 30.5039C32.1755 30.2185 31.9098 29.9528 31.3783 29.4213L31.1835 29.2264C30.8339 28.8768 30.6591 28.702 30.4833 28.5877C29.8204 28.1567 28.9658 28.1568 28.3029 28.5877C28.1271 28.702 27.9523 28.8768 27.6027 29.2264C27.4884 29.3408 27.4313 29.3979 27.3644 29.4457C27.127 29.6158 26.7828 29.6745 26.5024 29.5927C26.4235 29.5697 26.3612 29.5398 26.2365 29.4799C24.7869 28.7839 23.4282 27.8352 22.2266 26.6336Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
