import React from "react";
import { useTranslation } from "react-i18next";
import "./TaskStatusMenu.scss";

export enum StatusTabTypes {
  ACTIVE,
  FINISHED,
}

type TaskStatusMenuProps = {
  selectedStatus: StatusTabTypes;
  onStatusSelect: (status: StatusTabTypes) => void;
};

export const TaskStatusMenu: React.FC<TaskStatusMenuProps> = ({
  selectedStatus,
  onStatusSelect,
}) => {
  const { t } = useTranslation();

  const statusTypes = [
    {
      id: StatusTabTypes.ACTIVE,
      text: t("active"),
      color: "#37A6F6",
    },
    {
      id: StatusTabTypes.FINISHED,
      text: t("finished"),
      color: "#06D672",
    },
  ];

  return (
    <div className={"task-status-menu-container"}>
      {statusTypes.map((item) => {
        const isSelected = selectedStatus === item.id;
        return (
          <div
            className={`task-status-type-wrapper ${
              isSelected ? "selected" : ""
            }`}
            key={item.id}
            onClick={() => onStatusSelect(item.id)}
          >
            <div
              style={{ background: item.color }}
              className={"task-status-type-indicator"}
            />
            <div className={"task-status-type-text"}>{item.text}</div>
          </div>
        );
      })}
    </div>
  );
};
