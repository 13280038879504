import React from "react";
import "./ValidationText.scss";
import { WarningSvg } from "../../assets/svg";

interface IValidationText {
  message: string;
}

export const ValidationText: React.FC<IValidationText> = ({ message }) => {
  return (
    <div className={"validation-text-wrapper"}>
      <WarningSvg />
      <div className={"validation-text"}>{message}</div>
    </div>
  );
};
