import React from "react";
import "./Reviews.scss";
import { ReviewItem } from "../../../../components";
import ReviewerOne from "../../../../assets/png/ReviewerOne.png";
import ReviewerTwo from "../../../../assets/png/ReviewerTwo.png";
import ReviewerThree from "../../../../assets/png/ReviewerThree.png";
import { useTranslation } from "react-i18next";

export const Reviews = () => {
  const { t } = useTranslation();

  const reviewsData = [
    {
      id: 1,
      image: ReviewerOne,
      name: t("giorgi"),
      reviewerInfo: t("years", { age: 25 }),
      reviewText: t("review_one_text"),
      color: "#FF8900",
    },
    {
      id: 2,
      image: ReviewerTwo,
      name: t("nikusha"),
      reviewerInfo: t("years", { age: 21 }),
      reviewText: t("review_two_text"),
      color: "#06D672",
      alignSelf: "flex-start",
    },
    {
      id: 3,
      image: ReviewerThree,
      name: t("natia"),
      reviewerInfo: t("years", { age: 43 }),
      reviewText: t("review_three_text"),
      color: "#FF3100",
    },
  ];

  return (
    <div className={"reviews-container"}>
      {reviewsData?.map((item) => {
        return (
          <ReviewItem
            key={item.id}
            image={item.image}
            reviewerName={item.name}
            reviewerInfo={item.reviewerInfo}
            reviewText={item.reviewText}
            color={item.color}
            alignSelf={item?.alignSelf}
          />
        );
      })}
    </div>
  );
};
