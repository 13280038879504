import { GeneralResponseType } from "../types";
import { apiClient } from "../apiClient";
import { useMutation } from "@tanstack/react-query";

export type ClientUpdateReq = {
  firstName: string;
  lastName: string;
  cityId: number;
  avatar?: string;
};

export type ClientUpdateResp = GeneralResponseType<null>;

export const clientUpdate = (
  data: ClientUpdateReq,
): Promise<ClientUpdateResp> =>
  apiClient.post("/client/update", data).then((resp) => resp.data);

export const useClientUpdate = (
  onSuccess?: (data: ClientUpdateResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate, mutateAsync } = useMutation(clientUpdate, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
