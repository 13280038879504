import React from "react";

export const YoutubeBorderedSvg = ({ ...props }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="48" height="48" rx="12" fill="#F0F2F4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.792 17.4147C32.6521 17.6458 33.3325 18.3261 33.5635 19.1862C33.9872 20.7523 34 24.0001 34 24.0001C34 24.0001 34 27.2607 33.5764 28.8139C33.3453 29.674 32.665 30.3544 31.8049 30.5854C30.2516 31.0091 24 31.0091 24 31.0091C24 31.0091 17.7484 31.0091 16.1951 30.5854C15.335 30.3544 14.6547 29.674 14.4236 28.8139C14 27.2478 14 24.0001 14 24.0001C14 24.0001 14 20.7523 14.4108 19.199C14.6418 18.339 15.3222 17.6586 16.1823 17.4275C17.7356 17.0039 23.9872 16.9911 23.9872 16.9911C23.9872 16.9911 30.2388 16.9911 31.792 17.4147ZM27.1836 24.0001L21.9974 27.0039V20.9962L27.1836 24.0001Z"
        fill="#EF3653"
      />
    </svg>
  );
};
