import { apiClient } from "../apiClient";
import { useQuery } from "@tanstack/react-query";
import { GeneralResponseType, Option } from "../types";
import { UseQueryOptions } from "@tanstack/react-query/src/types";

export type ClientData = {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  city: Option;
  avatar: string;
};

type GetClientResp = GeneralResponseType<ClientData>;

export const getClient = (): Promise<GetClientResp> =>
  apiClient
    .get("/client/me")
    .then((resp) => resp.data)
    .catch((error) => {
      //resetting client data to undefined in case of 401 error
      if (error.response && error.response.status === 401) {
        return {
          data: undefined,
          status: undefined,
        };
      }
      throw error;
    });

export const useGetClient = (options?: UseQueryOptions<GetClientResp>) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["client_me"],
    queryFn: getClient,
    ...options,
  });
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
    refetch,
  };
};
