import React from "react";
import "./CustomTextArea.scss";
import TextArea from "antd/es/input/TextArea";
import { TextAreaProps } from "antd/lib/input";

type CustomTextAreaProps = TextAreaProps & {
  className?: string;
};

export const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  className,
  ...rest
}) => {
  return (
    <TextArea
      className={`custom-textarea ${className || ""}`}
      autoSize={false}
      {...rest}
    />
  );
};
