import React from "react";
import "./ClientInstructions.scss";
import { useTranslation } from "react-i18next";
import {
  AnnouncementSvg,
  MessageSvg,
  PersonCheckedSvg,
} from "../../../../assets/svg";
import { InstructionItem } from "../../../../components";

export type Instruction = {
  id: number;
  step: string;
  text: string;
  icon: JSX.Element;
};

export const ClientInstructions = () => {
  const { t } = useTranslation();

  const clientInstructions: Instruction[] = [
    {
      id: 1,
      step: "01",
      text: t("client_instruction_one"),
      icon: <AnnouncementSvg />,
    },
    {
      id: 2,
      step: "02",
      text: t("client_instruction_two"),
      icon: <MessageSvg />,
    },
    {
      id: 3,
      step: "03",
      text: t("client_instruction_three"),
      icon: <PersonCheckedSvg />,
    },
  ];

  return (
    <div className={"client-instructions-wrapper"}>
      <div className={"client-instructions-heading"}>{t("if_client")}</div>
      <div className={"client-instructions-steps-wrapper"}>
        {clientInstructions.map((item) => {
          return (
            <InstructionItem
              key={item.id}
              icon={item.icon}
              text={item.text}
              step={item.step}
            />
          );
        })}
      </div>
    </div>
  );
};
