import React from "react";
import "./Footer.scss";
import {
  AddressSvg,
  EmailFilledSvg,
  FacebookSvg,
  InstagramSvg,
  PhoneFilledSvg,
  WemplerFooterSvg,
  YoutubeSvg,
} from "../../assets/svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../configs/routeConfigs";
import { Helmet } from "react-helmet-async";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const footerItems = [
    {
      id: 1,
      icon: <AddressSvg />,
      title: t("address"),
      subTitle: t("address_street"),
    },
    {
      id: 2,
      icon: <PhoneFilledSvg />,
      title: t("phone"),
      subTitle: "0322 02 20 10",
    },
    {
      id: 3,
      icon: <EmailFilledSvg />,
      title: t("email"),
      subTitle: "info@wempler.com",
    },
  ];

  const socialMediaIcons = [
    {
      title: "facebook",
      link: "https://www.facebook.com/wempler/",
      icon: <FacebookSvg />,
    },
    {
      title: "instagram",
      link: "https://instagram.com/wempler",
      icon: <InstagramSvg />,
    },
    {
      title: "youtube",
      link: "https://www.youtube.com/channel/UCZYAQkB6Exw79DyUkZc_A0w",
      icon: <YoutubeSvg />,
    },
  ];

  return (
    <>
      <footer className={"footer-container"} id={"contact"}>
        <div className={"footer-container-inner"}>
          <div className={"footer-main-section-wrapper"}>
            <div className={"footer-main-text-wrapper"}>
              <div className={"footer-main-text"}>{t("contact_us")}</div>
              <div className={"footer-main-subtext"}>
                {t("if_you_have_questions")}
              </div>
            </div>
            <div className={"footer-social-media-wrapper responsive"}>
              {socialMediaIcons.map((item) => {
                return (
                  <a
                    key={item.title}
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={item.link}
                    className={"social-media-item"}
                  >
                    {item.icon}
                  </a>
                );
              })}
            </div>
          </div>
          <div className={"footer-info-wrapper"}>
            <div className={"footer-contact-info-wrapper"}>
              {footerItems.map((item) => {
                return (
                  <div key={item.id} className={"footer-info-item-wrapper"}>
                    {item.icon}
                    <div className={"footer-item-description-wrapper"}>
                      <div className={"footer-item-title"}>{item.title}</div>
                      <div className={"footer-item-subtitle"}>
                        {item.subTitle}
                      </div>
                    </div>
                  </div>
                );
              })}
              {/*TOP.GE COUNTER*/}
              <div id="top-ge-counter-container" data-site-id="117238" />
              <Helmet>
                <script async src="//counter.top.ge/counter.js" />
              </Helmet>
              {/*END TOP.GE COUNTER*/}
            </div>
            <div className={"footer-social-media-wrapper"}>
              {socialMediaIcons.map((item) => {
                return (
                  <a
                    key={item.title}
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={item.link}
                    id={`gtm-footer-${item.title}-icon-button`}
                    className={`social-media-item gtm-footer-${item.title}-icon-button`}
                  >
                    {item.icon}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </footer>
      <div className={"credentials-container"}>
        <div className={"credentials-container-inner"}>
          <WemplerFooterSvg />
          <div className={"credentials-right-container"}>
            <div
              id={"gtm-footer-faq-button"}
              className={"faq-text gtm-footer-faq-button"}
              onClick={() => navigate(routes.faq)}
            >
              {t("faq")}
            </div>
            <div
              id={"gtm-footer-cancellation-policy-button"}
              className={
                "cancellation-policy-text gtm-footer-cancellation-policy-button"
              }
              onClick={() => navigate(routes.cancellation)}
            >
              {t("cancellation_policy")}
            </div>
            <div
              id={"gtm-footer-privacy-policy-button"}
              className={"privacy-policy-text gtm-footer-privacy-policy-button"}
              onClick={() => navigate(routes.privacy)}
            >
              {t("privacy_policy")}
            </div>
            <div
              id={"gtm-footer-terms-button"}
              className={"terms-and-conditions-text gtm-footer-terms-button"}
              onClick={() => navigate(routes.terms)}
            >
              {t("terms_and_conditions")}
            </div>
            <div className={"website-text"}>WEMPLER.GE | 2022</div>
          </div>
        </div>
      </div>
    </>
  );
};
