import React from "react";

export const PhoneSvg = ({ ...props }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="phone-call-01">
        <path
          id="Icon"
          d="M12.3749 5.00008C13.1888 5.15889 13.9369 5.55696 14.5233 6.14336C15.1097 6.72976 15.5077 7.4778 15.6665 8.29175M12.3749 1.66675C14.0659 1.85461 15.6429 2.61189 16.8467 3.81425C18.0506 5.01662 18.8099 6.59259 18.9999 8.28341M9.18903 11.5526C8.18771 10.5513 7.39706 9.41912 6.81706 8.21111C6.76717 8.1072 6.74223 8.05524 6.72306 7.9895C6.65496 7.75587 6.70388 7.46899 6.84555 7.27113C6.88542 7.21546 6.93305 7.16783 7.02831 7.07257C7.31965 6.78123 7.46532 6.63556 7.56056 6.48908C7.91972 5.93667 7.91972 5.22452 7.56056 4.67211C7.46532 4.52563 7.31965 4.37996 7.02831 4.08862L6.86592 3.92623C6.42305 3.48336 6.20162 3.26192 5.9638 3.14164C5.49083 2.90241 4.93228 2.90241 4.45931 3.14164C4.22149 3.26192 4.00006 3.48336 3.55719 3.92623L3.42583 4.05759C2.98447 4.49894 2.7638 4.71962 2.59526 5.01964C2.40824 5.35257 2.27377 5.86964 2.27491 6.25149C2.27593 6.59562 2.34269 6.8308 2.47619 7.30117C3.19367 9.82901 4.5474 12.2143 6.53738 14.2043C8.52736 16.1943 10.9127 17.548 13.4405 18.2655C13.9109 18.399 14.1461 18.4657 14.4902 18.4668C14.872 18.4679 15.3891 18.3334 15.722 18.1464C16.022 17.9779 16.2427 17.7572 16.6841 17.3158L16.8154 17.1845C17.2583 16.7416 17.4797 16.5202 17.6 16.2824C17.8393 15.8094 17.8393 15.2508 17.6 14.7779C17.4797 14.5401 17.2583 14.3186 16.8154 13.8758L16.6531 13.7134C16.3617 13.422 16.216 13.2764 16.0696 13.1811C15.5172 12.8219 14.805 12.822 14.2526 13.1811C14.1061 13.2764 13.9604 13.422 13.6691 13.7134C13.5738 13.8086 13.5262 13.8562 13.4705 13.8961C13.2727 14.0378 12.9858 14.0867 12.7522 14.0186C12.6864 13.9994 12.6345 13.9745 12.5306 13.9246C11.3225 13.3446 10.1903 12.554 9.18903 11.5526Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
