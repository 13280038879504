import React, { useEffect, useState } from "react";
import "./PromotionModal.scss";
import { CustomModal } from "../../components";
import { PromotionAlertData } from "../../service/promotion/useGetPromotionAlerts";
import { CloseModalSvg } from "../../assets/svg/CloseModalSvg";
import { setLocalStoragePromotionIds } from "../../utils/helpers";

type PromotionModalProps = {
  alert: PromotionAlertData;
};

export const PromotionModal: React.FC<PromotionModalProps> = ({ alert }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (alert) {
      setModalOpen(true);
    }
  }, [alert]);

  return (
    <CustomModal
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      className={"promotion-modal"}
      footer={null}
      destroyOnClose
      centered
      afterClose={() => {
        setLocalStoragePromotionIds(alert.id);
      }}
    >
      {alert && (
        <div className={"promotion-content-wrapper"}>
          <div className={"promotion-banner-image-wrapper"}>
            <img
              className={"promotion-banner-image"}
              src={alert.bannerImage}
              alt={"banner"}
            />
          </div>
          <div
            className={"promotion-text-container"}
            style={{ backgroundColor: alert.backgroundColorHex }}
          >
            <div
              className={"promotion-modal-close"}
              onClick={() => setModalOpen(false)}
            >
              <CloseModalSvg />
            </div>
            <div className={"promotion-text-wrapper"}>
              <img
                className={"promotion-company-image"}
                src={alert.companyImage}
                alt={"logo"}
              />
              <div className={"promotion-company-title"}>{alert.title}</div>
              <div className={"promotion-company-description"}>
                {alert.description}
              </div>
            </div>
          </div>
        </div>
      )}
    </CustomModal>
  );
};
