import "./User.scss";
import { PersonSvg } from "../../../assets/svg/PersonSvg";
import { CustomButton } from "../../../components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetClient } from "../../../service/client/useGetClient";
import { getToken, removeToken } from "../../../utils/helpers";
import { Dropdown } from "antd";
import NoAvatar from "../../../assets/png/NoAvatar.png";
import { UserMenu } from "./UserMenu/UserMenu";
import { TasksModal } from "./TasksModal/TasksModal";
import { EditProfileModal } from "./EditProfileModal/EditProfileModal";
import { useWindowResize } from "../../../hooks";
import { EditProfileDrawer } from "./EditProfileDrawer/EditProfileDrawer";
import { EditProfileSvg } from "../../../assets/svg/EditProfileSvg";
import { TasksSvg } from "../../../assets/svg/TasksSvg";
import { LogOutSvg } from "../../../assets/svg/LogOutSvg";
import { TasksDrawer } from "./TasksDrawer/TasksDrawer";
import TagManager from "react-gtm-module";

type UserProps = {
  onAuthorizationClick: () => void;
};

export type MenuItemType = {
  label: string;
  icon: JSX.Element;
  onClick: () => void;
  className?: string;
};

export const User: React.FC<UserProps> = ({ onAuthorizationClick }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [tasksDrawerOpen, setTasksDrawerOpen] = useState<boolean>(false);

  const [tasksModalOpen, setTasksModalOpen] = useState<boolean>(false);
  const [editProfileModalOpen, setEditProfileModalOpen] =
    useState<boolean>(false);
  const { isLg } = useWindowResize();

  const { data: client, refetch: refetchClient } = useGetClient({
    refetchOnWindowFocus: !editProfileModalOpen,
  });

  const token = useMemo(() => {
    return getToken();
  }, [client]);

  const { t } = useTranslation();

  const menuItems: MenuItemType[] = [
    {
      label: t("edit"),
      icon: <EditProfileSvg />,
      onClick: () => setEditProfileModalOpen(true),
      className: "gtm-edit-profile-menu-item-button",
    },
    {
      label: t("tasks"),
      icon: <TasksSvg />,
      onClick: () => {
        isLg ? setTasksModalOpen(true) : setTasksDrawerOpen(true);
      },
      className: "gtm-tasks-menu-item-button",
    },
    {
      label: t("log_out"),
      icon: <LogOutSvg />,
      onClick: () => {
        if (client?.id) {
          TagManager.dataLayer({
            dataLayer: {
              event: "logout",
              user_id: client.id,
            },
          });
        }
        removeToken();
        refetchClient();
      },
      className: "gtm-log-out-menu-item-button",
    },
  ];

  return (
    <div className={"user-container"}>
      {token && client ? (
        <>
          {!isLg && (
            <EditProfileDrawer
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              menuItems={menuItems}
            />
          )}
          {!isLg && (
            <TasksDrawer
              open={tasksDrawerOpen}
              onClose={() => setTasksDrawerOpen(false)}
            />
          )}
          <Dropdown
            overlay={isLg ? <UserMenu menuItems={menuItems} /> : <></>}
            placement="bottomRight"
          >
            <div
              id={"gtm-header-user-avatar"}
              className={"avatar-wrapper gtm-header-user-avatar"}
              onClick={() => {
                if (!isLg) {
                  setDrawerOpen(true);
                }
              }}
            >
              <img
                className="avatar-img"
                src={client.avatar ?? NoAvatar}
                alt={"avatar"}
              />
            </div>
          </Dropdown>
          <TasksModal
            open={tasksModalOpen}
            onClose={() => setTasksModalOpen(false)}
          />
          <EditProfileModal
            open={editProfileModalOpen}
            onClose={() => setEditProfileModalOpen(false)}
          />
        </>
      ) : (
        <CustomButton
          prefixIcon={<PersonSvg />}
          id={"gtm-header-authorization-button"}
          className={"authorization-button gtm-header-authorization-button"}
          onClick={onAuthorizationClick}
        >
          {t("authorization")}
        </CustomButton>
      )}
    </div>
  );
};
