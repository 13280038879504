import React from "react";

export const WarningSvg = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.99935 5.8623V9.19564M8.99935 12.529H9.00768M17.3327 9.19564C17.3327 13.798 13.6017 17.529 8.99935 17.529C4.39698 17.529 0.666016 13.798 0.666016 9.19564C0.666016 4.59327 4.39698 0.862305 8.99935 0.862305C13.6017 0.862305 17.3327 4.59327 17.3327 9.19564Z"
        stroke="#FF3100"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
