import React from "react";

export const MessagesSvg = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="32" cy="32" r="32" fill="#06D672" />
      <path
        d="M29.3346 36.0001L25.2343 40.1517C24.6623 40.7308 24.3764 41.0203 24.1306 41.0407C23.9173 41.0585 23.7085 40.9727 23.5692 40.8103C23.4087 40.623 23.4087 40.2161 23.4087 39.4021V37.3222C23.4087 36.592 22.8107 36.0637 22.0882 35.9578V35.9578C20.3396 35.7017 18.9663 34.3284 18.7102 32.5798C18.668 32.2915 18.668 31.9474 18.668 31.2593V25.0667C18.668 22.8265 18.668 21.7064 19.1039 20.8508C19.4874 20.0981 20.0994 19.4862 20.852 19.1027C21.7077 18.6667 22.8278 18.6667 25.068 18.6667H34.9346C37.1748 18.6667 38.295 18.6667 39.1506 19.1027C39.9032 19.4862 40.5152 20.0981 40.8987 20.8508C41.3346 21.7064 41.3346 22.8265 41.3346 25.0667V30.6667M41.3346 45.3334L38.4328 43.3159C38.0248 43.0323 37.8209 42.8905 37.5989 42.79C37.4019 42.7008 37.1948 42.6358 36.9821 42.5966C36.7424 42.5525 36.494 42.5525 35.9972 42.5525H33.6013C32.1078 42.5525 31.3611 42.5525 30.7907 42.2618C30.2889 42.0062 29.8809 41.5982 29.6253 41.0964C29.3346 40.526 29.3346 39.7793 29.3346 38.2858V34.9334C29.3346 33.4399 29.3346 32.6932 29.6253 32.1228C29.8809 31.621 30.2889 31.2131 30.7907 30.9574C31.3611 30.6667 32.1078 30.6667 33.6013 30.6667H41.068C42.5614 30.6667 43.3082 30.6667 43.8786 30.9574C44.3804 31.2131 44.7883 31.621 45.044 32.1228C45.3346 32.6932 45.3346 33.4399 45.3346 34.9334V38.5525C45.3346 39.795 45.3346 40.4162 45.1316 40.9063C44.861 41.5597 44.3419 42.0788 43.6885 42.3495C43.1984 42.5525 42.5771 42.5525 41.3346 42.5525V45.3334Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
