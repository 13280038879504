import React, { PropsWithChildren } from "react";
import "./CustomButton.scss";
import { Button, ButtonProps } from "antd";

type CustomButtonProps = ButtonProps &
  PropsWithChildren<{
    prefixIcon?: JSX.Element;
    suffixIcon?: JSX.Element;
    className?: string;
  }>;

export const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  prefixIcon,
  suffixIcon,
  className,
  ...rest
}) => {
  return (
    <Button
      className={`custom-button ${className ?? ""}`}
      type={"primary"}
      {...rest}
    >
      {prefixIcon && React.cloneElement(prefixIcon)}
      {children ? <div className={"button-text"}>{children}</div> : <></>}
      {suffixIcon && React.cloneElement(suffixIcon)}
    </Button>
  );
};
