import React, { useState } from "react";
import "./TermsAndConditionsModal.scss";
import { CloseSvg } from "../../assets/svg";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../components";
import { useNavigate } from "react-router-dom";
import { routes } from "../../configs/routeConfigs";

export const TermsAndConditionsModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  return (
    <CustomModal
      open={modalOpen}
      className={"terms-and-conditions-modal"}
      title={t("wempler_terms_and_conditions")}
      footer={<></>}
      closeIcon={<CloseSvg />}
      onCancel={() => setModalOpen(false)}
      afterClose={() => {
        navigate(routes.home);
      }}
      maskClosable
      destroyOnClose
    >
      <div className={"bold"}>{t("terms_heading")}</div>

      <div className={"divider"} />

      {Array.from(Array(19)).map((value, i) => {
        return (
          <p className={"section"} key={`section_${i + 1}`}>
            <div className={"bold"}>{t(`section_${i + 1}_left`)}</div>
            <div>{t(`section_${i + 1}_right`)}</div>
          </p>
        );
      })}

      {Array.from(Array(6)).map((value, i) => {
        return <p key={`paragraph_${i + 1}`}>{t(`paragraph_${i + 1}`)}</p>;
      })}

      <div className={"divider"} />

      <div className={"heading bold"}>{t("section_heading_1")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"}>
        {Array.from(Array(3)).map((value, i) => {
          return (
            <li key={`list_item_${i + 1}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 1}`)}
            </li>
          );
        })}
      </ol>

      <div className={"heading bold"}>{t("section_heading_2")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={4}>
        <li className={"ordered-list-item"}>
          {t("list_item_4")}
          <ul className={"unordered-list"}>
            {Array.from(Array(7)).map((value, i) => {
              return (
                <div
                  key={`list_item_4_nested_${i + 1}`}
                  className={"unordered-list-item"}
                >
                  {t(`list_item_4_nested_${i + 1}`)}
                </div>
              );
            })}
          </ul>
        </li>
        {Array.from(Array(2)).map((value, i) => {
          return (
            <li key={`list_item_${i + 5}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 5}`)}
            </li>
          );
        })}
      </ol>

      <div className={"divider"} />

      <div className={"heading bold"}>{t("section_heading_3")}</div>

      <div className={"divider"} />

      <div className={"bold underlined"}>{t("subheading_1_section_3")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={7}>
        {Array.from(Array(2)).map((value, i) => {
          return (
            <li key={`list_item_${i + 7}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 7}`)}
            </li>
          );
        })}
      </ol>

      <div className={"bold underlined"}>{t("subheading_2_section_3")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={9}>
        {Array.from(Array(3)).map((value, i) => {
          return (
            <li key={`list_item_${i + 9}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 9}`)}
            </li>
          );
        })}
      </ol>

      <div className={"bold underlined"}>{t("subheading_3_section_3")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={12}>
        {Array.from(Array(2)).map((value, i) => {
          return (
            <li key={`list_item_${i + 12}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 12}`)}
            </li>
          );
        })}
      </ol>

      <div className={"divider"} />

      <div className={"heading bold"}>{t("section_heading_4")}</div>

      <div className={"divider"} />

      <div className={"bold underlined"}>{t("subheading_1_section_4")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={14}>
        {Array.from(Array(2)).map((value, i) => {
          return (
            <li key={`list_item_${i + 14}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 14}`)}
            </li>
          );
        })}
      </ol>

      <div className={"bold underlined"}>{t("subheading_2_section_4")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={16}>
        {Array.from(Array(7)).map((value, i) => {
          return (
            <li key={`list_item_${i + 16}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 16}`)}
            </li>
          );
        })}
      </ol>

      <div className={"bold underlined"}>{t("subheading_3_section_4")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={23}>
        {Array.from(Array(3)).map((value, i) => {
          return (
            <li key={`list_item_${i + 23}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 23}`)}
            </li>
          );
        })}
      </ol>

      <div className={"divider"} />

      <div className={"heading bold"}>{t("section_heading_5")}</div>

      <div className={"divider"} />

      <div className={"bold underlined"}>{t("subheading_1_section_5")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={26}>
        {Array.from(Array(7)).map((value, i) => {
          return (
            <li key={`list_item_${i + 26}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 26}`)}
            </li>
          );
        })}
      </ol>

      <div className={"divider"} />

      <div className={"heading bold"}>{t("section_heading_6")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={33}>
        {Array.from(Array(5)).map((value, i) => {
          return (
            <li key={`list_item_${i + 33}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 33}`)}
            </li>
          );
        })}
      </ol>

      <div className={"divider"} />

      <div className={"heading bold"}>{t("section_heading_7")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={38}>
        <li className={"ordered-list-item"}>
          {t("list_item_38")}
          <ul className={"unordered-list"}>
            {Array.from(Array(5)).map((value, i) => {
              return (
                <div
                  key={`list_item_38_nested_${i + 1}`}
                  className={"unordered-list-item"}
                >
                  {t(`list_item_38_nested_${i + 1}`)}
                </div>
              );
            })}
          </ul>
        </li>
        {Array.from(Array(3)).map((value, i) => {
          return (
            <li key={`list_item_${i + 39}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 39}`)}
            </li>
          );
        })}
      </ol>

      <div className={"divider"} />

      <div className={"heading bold"}>{t("section_heading_8")}</div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={42}>
        {Array.from(Array(4)).map((value, i) => {
          return (
            <li key={`list_item_${i + 42}`} className={"ordered-list-item"}>
              {t(`list_item_${i + 42}`)}
            </li>
          );
        })}
      </ol>
    </CustomModal>
  );
};
