import React, { useMemo } from "react";
import "./Contact.scss";
import { CustomButton, SectionHeading } from "../../../components";
import { useTranslation } from "react-i18next";
import { ArrowSvg } from "../../../assets/svg";
import { Reviews } from "./Reviews/Reviews";
import { Lang } from "../../../i18n/resources";

export const Contact = () => {
  const { t, i18n } = useTranslation();

  const lang = i18n.language as Lang;

  const formUrl = useMemo(() => {
    switch (lang) {
      case "en":
        return "https://docs.google.com/forms/d/e/1FAIpQLSdcQnkIg2O1gTQyTD6bOWy2Mm--l24LFntCrjrzFgWBtgJKGg/viewform?usp=pp_url";
      case "ru":
        return "https://docs.google.com/forms/d/e/1FAIpQLScBFlPu-CRPMuXmf5ebT8X4QiZGiYlyyNhavRuG6PaCh7R6Kg/viewform?usp=pp_url";
      default:
        return "https://docs.google.com/forms/d/e/1FAIpQLSfzfOlkhTPQC7VjzVDRIFi_Fky60q7r9dyrPwv19V7_FMrAEw/viewform?usp=sf_link";
    }
  }, [lang]);

  return (
    <div data-aos="fade-in" className={"contact-container"}>
      <div className={"contact-container-wrapper"}>
        <div data-aos="fade-right" className={"contact-left-wrapper"}>
          <SectionHeading
            className="contact-heading"
            title={t("work_flexibly")}
          />
          <div className={"contact-sub-text"}>{t("contact_subtext")}</div>
          <CustomButton
            suffixIcon={<ArrowSvg />}
            id={"gtm-fill-out-form-button"}
            className={"contact-button gtm-fill-out-form-button"}
            onClick={() => window.open(formUrl)}
          >
            {t("fill_out_form")}
          </CustomButton>
        </div>
        <div className={"contact-right-wrapper"}>
          <Reviews />
        </div>
      </div>
    </div>
  );
};
