import React from "react";
import { EngFlagSvg, GeoFlagSvg, RusFlagSvg } from "../assets/svg";
import { Languages } from "../i18n/resources";

export type LanguageItemType = {
  id: number;
  value: Languages;
  flag: JSX.Element;
  label: "ქართული" | "English" | "Русский";
  className?: string;
};

export const languages: LanguageItemType[] = [
  {
    id: 1,
    value: Languages.KA,
    flag: <GeoFlagSvg />,
    label: "ქართული",
    className: "gtm-language-ka-button",
  },
  {
    id: 2,
    value: Languages.EN,
    flag: <EngFlagSvg />,
    label: "English",
    className: "gtm-language-en-button",
  },
  {
    id: 3,
    value: Languages.RU,
    flag: <RusFlagSvg />,
    label: "Русский",
    className: "gtm-language-ru-button",
  },
];
