import { useSearchParams } from "react-router-dom";

export enum PaymentStatus {
  SUCCESS = "success",
  FAIL = "fail",
}

export const usePaymentParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    paymentStatus: searchParams.get("payment"),
    setPaymentStatus: (value: PaymentStatus) => {
      searchParams.set("payment", value);
      setSearchParams(searchParams);
    },
    removePaymentStatus: () => {
      searchParams.delete("payment");
      setSearchParams(searchParams);
    },
  };
};
