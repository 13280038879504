import { GeneralResponseType } from "../types";
import { apiClient } from "../apiClient";
import { useMutation } from "@tanstack/react-query";

type UserLoginReq = {
  phoneNumber: string;
  hash: string;
  code: string;
};

type UserLoginData = {
  accessToken: string;
  refreshToken: string;
};

export type UserLoginResp = GeneralResponseType<UserLoginData>;

export const userLogin = (data: UserLoginReq): Promise<UserLoginResp> =>
  apiClient.post("/auth/gosmsLogin", data).then((resp) => resp.data);

export const useUserLogin = (
  onSuccess?: (data: UserLoginResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(userLogin, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
