import { GeneralResponseType } from "../types";
import { apiClient } from "../apiClient";
import { useMutation } from "@tanstack/react-query";

type ClientUniqueCheckReq = {
  phoneNumber: string;
};

type ClientUniqueCheckData = {
  isPhoneNumberUnique: boolean;
};

export type ClientUniqueCheckResp = GeneralResponseType<ClientUniqueCheckData>;

export const clientUniqueCheck = (
  data: ClientUniqueCheckReq,
): Promise<ClientUniqueCheckResp> =>
  apiClient.post("/client/uniqueCheck", data).then((resp) => resp.data);

export const useClientUniqueCheck = (
  onSuccess?: (data: ClientUniqueCheckResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(clientUniqueCheck, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
