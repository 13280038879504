import { AboutUs, Footer, Header, HowItWorks, SendTask } from "./layouts";
import React, { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { PromotionModal } from "./layouts/PromotionModal/PromotionModal";
import {
  PromotionAlertData,
  useGetPromotionAlerts,
} from "./service/promotion/useGetPromotionAlerts";
import { getLocalStoragePromotionIds } from "./utils/helpers";
import { BecomeDoer } from "./layouts/BecomeDoer/BecomeDoer";

export const MainComponent = () => {
  const location = useLocation();
  const headerHeight = 116;
  const { data: alerts } = useGetPromotionAlerts();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        window.scroll(0, element.offsetTop - headerHeight);
      }
    }
  }, [location]);

  const currentAlert = useMemo((): PromotionAlertData | undefined => {
    const localStoragePromotionIds = getLocalStoragePromotionIds();
    return alerts?.find(
      (alert: PromotionAlertData) =>
        !localStoragePromotionIds.includes(alert.id),
    );
  }, [alerts]);

  return (
    <>
      <Header />
      <AboutUs />
      <HowItWorks />
      <SendTask />
      <BecomeDoer />
      <Footer />
      <Outlet />
      {currentAlert && <PromotionModal alert={currentAlert} />}
    </>
  );
};
