import "./MenuItem.scss";
import React from "react";

type MenuItemProps = {
  icon?: JSX.Element;
  label: string;
  onClick?: () => void;
};

export const MenuItem: React.FC<MenuItemProps> = ({ icon, label, onClick }) => {
  return (
    <div className={"menu-item-container"} onClick={onClick}>
      {icon}
      <div className={"menu-item-label"}>{label}</div>
    </div>
  );
};
