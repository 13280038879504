import React from "react";

export const FacebookBorderedSvg = ({ ...props }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="48" height="48" rx="12" fill="#F0F2F4" />
      <path
        d="M34 24.0607C34 18.504 29.5233 14 24 14C18.4767 14 14 18.504 14 24.0607C14 29.0833 17.656 33.2453 22.4373 34V26.9693H19.8987V24.06H22.4373V21.844C22.4373 19.3227 23.93 17.9293 26.2147 17.9293C27.308 17.9293 28.4533 18.126 28.4533 18.126V20.602H27.1913C25.9493 20.602 25.5627 21.378 25.5627 22.174V24.0607H28.336L27.8927 26.9687H25.5627V34C30.344 33.2453 34 29.0833 34 24.0607Z"
        fill="#EF3653"
      />
    </svg>
  );
};
