import React from "react";

export const EmailFilledSvg = ({ ...props }) => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="24" cy="24.7705" r="24" fill="#A0ACBA" />
      <path
        d="M14 19.7705L22.1649 25.486C22.8261 25.9488 23.1567 26.1802 23.5163 26.2698C23.8339 26.349 24.1661 26.349 24.4837 26.2698C24.8433 26.1802 25.1739 25.9488 25.8351 25.486L34 19.7705M18.8 32.7705H29.2C30.8802 32.7705 31.7202 32.7705 32.362 32.4435C32.9265 32.1559 33.3854 31.697 33.673 31.1325C34 30.4907 34 29.6507 34 27.9705V21.5705C34 19.8904 34 19.0503 33.673 18.4085C33.3854 17.8441 32.9265 17.3851 32.362 17.0975C31.7202 16.7705 30.8802 16.7705 29.2 16.7705H18.8C17.1198 16.7705 16.2798 16.7705 15.638 17.0975C15.0735 17.3851 14.6146 17.8441 14.327 18.4085C14 19.0503 14 19.8904 14 21.5705V27.9705C14 29.6507 14 30.4907 14.327 31.1325C14.6146 31.697 15.0735 32.1559 15.638 32.4435C16.2798 32.7705 17.1198 32.7705 18.8 32.7705Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
