import ka from "./ka";
import en from "./en";
import ru from "./ru";

export enum Languages {
  EN = "en",
  KA = "ka",
  RU = "ru",
}

interface Translations {
  en: typeof en;
  ka: typeof ka;
  ru: typeof ru;
}

const translations: Translations = {
  en,
  ka,
  ru,
};

export type Lang = keyof Translations;

export default translations;
