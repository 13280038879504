import React from "react";
import { Form } from "antd";
import {
  CustomButton,
  CustomTextArea,
  SectionHeading,
  ValidationText,
} from "../../../components";
import { ArrowSvg } from "../../../assets/svg";
import { useTranslation } from "react-i18next";

interface IDescribeTaskStep {
  handleNext: () => void;
}

export const DescribeTaskStep: React.FC<IDescribeTaskStep> = ({
  handleNext,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <SectionHeading
          className={"main-heading"}
          title={t("describe_task")}
          h1Heading
        />
        <div className={"main-input-text"}>{t("help_question")}</div>
      </div>
      <div className={"with-validation"}>
        <Form.Item
          name={"description"}
          className={"step-one-form-item"}
          // validateTrigger={[]}
          // validateStatus={"validating"}
          // rules={formValidation.required}
          rules={[
            {
              required: true,
              message: <ValidationText message={t("field_is_required")} />,
            },
          ]}
        >
          <CustomTextArea
            placeholder={t("i_need")}
            id={"gtm-task-flow-describe-task-textarea"}
            className={"main-textarea gtm-task-flow-describe-task-textarea"}
          />
        </Form.Item>
      </div>
      <CustomButton
        suffixIcon={<ArrowSvg />}
        onClick={handleNext}
        id={"gtm-task-flow-describe-task-next-button"}
        className={"gtm-task-flow-describe-task-next-button"}
      >
        {t("get_help")}
      </CustomButton>
    </>
  );
};
