import "./TasksModal.scss";
import { CustomModal } from "../../../../components";
import React from "react";
import { useTranslation } from "react-i18next";
import { TasksContent } from "./TasksContent/TasksContent";

type TasksModalProps = {
  open: boolean;
  onClose: () => void;
};

export const TasksModal: React.FC<TasksModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      open={open}
      className={"tasks-modal"}
      title={t("tasks")}
      footer={false}
      onCancel={onClose}
      maskClosable
    >
      <TasksContent open={open} />
    </CustomModal>
  );
};
