import React from "react";
import "./Description.scss";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../../../components";
import { AppStoreSvg } from "../../../assets/svg";
import { GooglePlaySvg } from "../../../assets/svg";
import PhoneImg from "../../../assets/png/Phone.png";
import { useWindowResize } from "../../../hooks";

export const Description = () => {
  const { t } = useTranslation();
  const { isLg } = useWindowResize();

  const appItems = [
    {
      id: "app-store",
      name: "app store",
      icon: <AppStoreSvg />,
      link: "https://apps.apple.com/us/app/wempler/id1644381619",
    },
    {
      id: "google-play",
      name: "google play",
      icon: <GooglePlaySvg />,
      link: "https://play.google.com/store/apps/details?id=ge.wempler.app.orderer&hl=en&gl=US",
    },
  ];

  return (
    <div data-aos="fade-in" className={"description-container"}>
      <div className={"description-container-inner"}>
        <div data-aos="fade-right" className={"description-left-wrapper"}>
          <SectionHeading
            className={"description-heading"}
            title={t("what_is_wempler")}
          />
          <div className={"description-sub-text one"}>
            {t("description_subtext_one")}
          </div>
          {/*<div className={"description-sub-text two"}>{t("description_subtext_two")}</div>*/}
          <div className={"download-app-wrapper"}>
            {appItems.map((item) => {
              return (
                <a
                  className={"download-app-item-wrapper"}
                  key={item.id}
                  target={"_blank"}
                  rel={"noreferrer"}
                  href={item.link}
                >
                  {item.icon}
                  <div className={"download-app-item-text-wrapper"}>
                    {/*<div className={"download-app-item-text"}>{t("soon")}</div>*/}
                    {/*<div className={"download-app-item-text"}>{t("on_store", {store: item.name.toUpperCase()})}</div>*/}
                    <div className={"download-app-item-text"}>
                      {item.name.toUpperCase()}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <div className={"description-right-wrapper"}>
          <div
            data-aos="fade-left"
            data-aos-anchor=".description-left-wrapper"
            className={"about-us-hand-wrapper"}
          >
            <img src={PhoneImg} alt={"phone"} className={"hand-image"} />
          </div>
        </div>
      </div>
    </div>
  );
};
