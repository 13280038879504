import { GeneralResponseType } from "../types";
import { apiClient } from "../apiClient";
import { useMutation } from "@tanstack/react-query";

type PayTaskReq = {
  taskId: string;
  saveCard: boolean;
};

type PayTaskData = {
  transactionId: string;
  paymentUrl: string;
};

export type PayTaskResp = GeneralResponseType<PayTaskData>;

export const payTask = (data: PayTaskReq): Promise<PayTaskResp> =>
  apiClient.post("/task/payment", data).then((resp) => resp.data);

export const useTaskPayment = (
  onSuccess?: (data: PayTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(payTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
