import React, { useEffect } from "react";
import {
  AppStoreOutlineSvg,
  CloseSvg,
  FacebookBorderedSvg,
  GooglePlayOutlineSvg,
  InstagramBorderedSvg,
  PhoneSvg,
  WemplerSvg,
  YoutubeBorderedSvg,
} from "../../../assets/svg";
import { Drawer as AntdDrawer } from "antd";
import { CustomButton } from "../../../components";
import { languages } from "../../../configs/globalConfigs";
import { useTranslation } from "react-i18next";
import { useWindowResize } from "../../../hooks";
import { Lang } from "../../../i18n/resources";
import { HeaderItem } from "../Header";

interface IDrawer {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  menuItems: HeaderItem[];
  handleLanguageChange: (lang: Lang) => void;
}

export const Drawer: React.FC<IDrawer> = ({
  drawerOpen,
  setDrawerOpen,
  menuItems,
  handleLanguageChange,
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language as Lang;

  const { isLg } = useWindowResize();

  useEffect(() => {
    if (isLg) setDrawerOpen(false);
  }, [isLg]);

  return (
    <AntdDrawer
      title={
        <div className={"drawer-title-wrapper"}>
          <WemplerSvg />
          <div
            className={"drawer-close-icon"}
            onClick={() => setDrawerOpen(false)}
          >
            <CloseSvg />
          </div>
        </div>
      }
      placement={"top"}
      closable
      open={drawerOpen}
      className={"drawer-container"}
      onClose={() => setDrawerOpen(false)}
    >
      <div className={"drawer-top-wrapper"}>
        <ul className={"drawer-list"}>
          {menuItems.map((item) => {
            return (
              <li
                className={`drawer-list-item ${item.className}`}
                key={item.id}
              >
                <a
                  className="drawer-list-item-link"
                  href={`#${item.id}`}
                  onClick={() => setDrawerOpen(false)}
                >
                  {item.text}
                </a>
              </li>
            );
          })}
        </ul>
        <CustomButton
          prefixIcon={<PhoneSvg />}
          className={"drawer-contact-us-button"}
          onClick={() => (window.location.href = "tel:0322022010")}
        >
          0322 02 20 10
        </CustomButton>
        <div className={"download-app-wrapper"}>
          {/*<div className={"download-app-text"}>{t("soon_available")}</div>*/}
          <div className={"download-app-buttons-wrapper"}>
            <a
              className={"download-app-button-wrapper"}
              target={"_blank"}
              rel={"noreferrer"}
              href={
                "https://play.google.com/store/apps/details?id=ge.wempler.app.orderer&hl=en&gl=US"
              }
            >
              <CustomButton
                prefixIcon={<GooglePlayOutlineSvg />}
                className={"download-app-button google-play"}
              >
                google play
              </CustomButton>
            </a>
            <a
              className={"download-app-button-wrapper"}
              target={"_blank"}
              rel={"noreferrer"}
              href={"https://apps.apple.com/us/app/wempler/id1644381619"}
            >
              <CustomButton
                prefixIcon={<AppStoreOutlineSvg />}
                className={"download-app-button app-store"}
              >
                app store
              </CustomButton>
            </a>
          </div>
        </div>
        <div className={"choose-language-wrapper"}>
          <div className={"choose-language-text"}>{t("choose_language")}</div>
          <div className={"languages-wrapper"}>
            {languages.map((item) => {
              return (
                <div
                  key={item.id}
                  className={"language-wrapper"}
                  onClick={() => handleLanguageChange(item.value)}
                >
                  {item.flag}
                  <div
                    className={`language-text ${
                      item.value === lang ? "selected" : ""
                    }`}
                  >
                    {item.label}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={"drawer-bottom-wrapper"}>
        <div className={"social-media-icons-wrapper"}>
          <a
            href={"https://www.facebook.com/Wempler/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <FacebookBorderedSvg />
          </a>
          <a
            href={"https://www.instagram.com/wempler/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <InstagramBorderedSvg />
          </a>
          <a
            href={"https://www.youtube.com/channel/UCZYAQkB6Exw79DyUkZc_A0w"}
            target={"_blank"}
            rel="noreferrer"
          >
            <YoutubeBorderedSvg />
          </a>
        </div>
        <div className={"credits-text"}>WEMPLER.GE | 2022</div>
      </div>
    </AntdDrawer>
  );
};
