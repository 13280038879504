import React from "react";
import { CustomDrawer } from "../../../../components/CustomDrawer/CustomDrawer";
import { MenuItemType } from "../User";
import { MenuItem } from "../MenuItem/MenuItem";
import "./EditProfileDrawer.scss";
import { useTranslation } from "react-i18next";

type EditProfileDrawerProps = {
  open: boolean;
  onClose: () => void;
  menuItems: MenuItemType[];
};

export const EditProfileDrawer: React.FC<EditProfileDrawerProps> = ({
  open,
  onClose,
  menuItems,
}) => {
  const { t } = useTranslation();
  return (
    <CustomDrawer
      title={t("profile")}
      closeIcon={false}
      placement={"bottom"}
      closable
      open={open}
      className={"edit-profile-drawer"}
      onClose={onClose}
    >
      <div className={"edit-profile-drawer-items-wrapper"}>
        {menuItems.map((item, index) => {
          return (
            <MenuItem
              label={item.label}
              icon={item.icon}
              onClick={() => {
                item.onClick();
                onClose();
              }}
              key={index}
            />
          );
        })}
      </div>
    </CustomDrawer>
  );
};
