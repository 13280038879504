import { Menu } from "antd";
import React from "react";
import "./UserMenu.scss";
import { MenuItem } from "../MenuItem/MenuItem";
import { MenuItemType } from "../User";

type UserMenuProps = {
  menuItems: MenuItemType[];
};

export const UserMenu: React.FC<UserMenuProps> = ({ menuItems }) => {
  return (
    <Menu className={"user-menu"}>
      {menuItems.map((item, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={item.onClick}
            className={"user-menu-item"}
          >
            <MenuItem label={item.label} icon={item.icon} />
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
