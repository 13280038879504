/*RESPONSE TYPES*/

type Status = {
  code: number;
  message: string;
};

export type GeneralResponseType<ResponseDataType> = {
  data: ResponseDataType;
  status: Status;
};

/*GENERAL TYPES*/

export type Option = {
  id: string | number;
  name: string;
};

/*GENERAL ENUMS*/

export enum LanguageHeaders {
  EN = "en-US",
  KA = "ka-GE",
  RU = "ru-RU",
}

export enum TaskStatuses {
  Created = 1, //active
  Assigned = 2, //active
  Accepted = 3, //active
  InProgress = 4, //active
  Completed = 5, //done
  Rejected = 6, //done
  Refunded = 7, //done
}
