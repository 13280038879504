import { Select, SelectProps } from "antd";
import React from "react";
import "./CustomSelect.scss";
import { Option as SelectOption } from "../../service/types";

type CustomSelectProps = SelectProps<any, SelectOption> & {
  className?: string;
  popupClassName?: string;
  options?: SelectOption[];
};

const { Option } = Select;

export const CustomSelect: React.FC<CustomSelectProps> = ({
  className,
  popupClassName,
  options,
  ...props
}) => {
  return (
    <Select
      className={`custom-select ${className ?? ""}`}
      popupClassName={`custom-select-dropdown ${popupClassName ?? ""}`}
      {...props}
    >
      {options?.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};
