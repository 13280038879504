import "./CustomDrawer.scss";
import { Drawer as AntdDrawer, DrawerProps } from "antd";
import React, { PropsWithChildren } from "react";
import { CloseSvg } from "../../assets/svg";

type CustomDrawerProps = PropsWithChildren<
  DrawerProps & {
    title: string;
    className?: string;
    onClose?: () => void;
  }
>;

export const CustomDrawer: React.FC<CustomDrawerProps> = ({
  className,
  title,
  onClose,
  children,
  ...props
}) => {
  return (
    <AntdDrawer
      className={`custom-drawer ${className ?? ""}`}
      title={
        <div className={"custom-drawer-title-wrapper"}>
          <div className={"title-text"}>{title}</div>
          <div className={"drawer-close-icon"} onClick={onClose}>
            <CloseSvg />
          </div>
        </div>
      }
      onClose={onClose}
      {...props}
    >
      {children}
    </AntdDrawer>
  );
};
