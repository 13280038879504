import React from "react";

export const CallSvg = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="32" cy="32" r="32" fill="#06D672" />
      <path
        d="M36.0008 21.3333V28M36.0008 28L42.6674 28M36.0008 28L44.0007 20M29.6368 34.4841C28.0347 32.882 26.7697 31.0705 25.8417 29.1376C25.7618 28.9714 25.7219 28.8883 25.6913 28.7831C25.5823 28.4093 25.6606 27.9503 25.8873 27.6337C25.951 27.5446 26.0273 27.4684 26.1797 27.316C26.6458 26.8498 26.8789 26.6168 27.0313 26.3824C27.6059 25.4985 27.6059 24.3591 27.0313 23.4752C26.8789 23.2409 26.6458 23.0078 26.1797 22.5417L25.9198 22.2818C25.2113 21.5732 24.857 21.2189 24.4765 21.0265C23.7197 20.6437 22.826 20.6437 22.0693 21.0265C21.6888 21.2189 21.3345 21.5732 20.6259 22.2818L20.4157 22.492C19.7095 23.1982 19.3565 23.5513 19.0868 24.0313C18.7876 24.564 18.5724 25.3913 18.5742 26.0023C18.5759 26.5529 18.6827 26.9292 18.8963 27.6817C20.0442 31.7263 22.2102 35.5428 25.3942 38.7267C28.5782 41.9107 32.3946 44.0767 36.4392 45.2246C37.1918 45.4382 37.5681 45.545 38.1187 45.5467C38.7296 45.5485 39.5569 45.3334 40.0896 45.0341C40.5697 44.7645 40.9227 44.4114 41.6289 43.7052L41.8391 43.495C42.5477 42.7864 42.902 42.4322 43.0944 42.0516C43.4772 41.2949 43.4772 40.4012 43.0944 39.6445C42.902 39.264 42.5477 38.9097 41.8391 38.2011L41.5793 37.9412C41.1131 37.4751 40.88 37.242 40.6457 37.0896C39.7618 36.515 38.6224 36.515 37.7385 37.0896C37.5042 37.242 37.2711 37.4751 36.8049 37.9412C36.6525 38.0937 36.5763 38.1699 36.4872 38.2337C36.1707 38.4603 35.7116 38.5386 35.3378 38.4296C35.2327 38.399 35.1495 38.3591 34.9833 38.2792C33.0505 37.3512 31.2389 36.0862 29.6368 34.4841Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
