import React from "react";
import "./IfYouWantToHelp.scss";
import { DoerInstructions } from "./DoerInstructions/DoerInstructions";

export const IfYouWantToHelp = () => {
  return (
    <div data-aos="fade-in" className={"if-you-want-to-help-container"}>
      <div className={"if-you-want-to-help-wrapper"}>
        <DoerInstructions />
      </div>
    </div>
  );
};
