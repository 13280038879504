import { GeneralResponseType } from "../types";
import { apiClient } from "../apiClient";
import { useMutation } from "@tanstack/react-query";

export enum SenderTypes {
  Web = 1,
  App = 2,
  Other = 3,
}

type CreateTaskReq = {
  Title: string;
  ClientId: string;
  Address: string;
  CityId: number;
  Description: string;
  WemplerTaskSenderType: SenderTypes;
  Files?: [];
};

type CreateTaskData = {
  taskId: string;
};

export type CreateTaskResp = GeneralResponseType<CreateTaskData>;

export const createTask = (data: CreateTaskReq): Promise<CreateTaskResp> =>
  apiClient
    .post("/task/create", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp) => resp.data);

export const useCreateTask = (
  onSuccess?: (data: CreateTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(createTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
