import React from "react";
import { Form, FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import {
  CustomButton,
  CustomInput,
  SectionHeading,
  ValidationText,
} from "../../../components";
import { ArrowSvg } from "../../../assets/svg";
import { BackButton } from "../components/BackButton/BackButton";
import { MainSteps } from "../SendTask";

interface IContactInfoStep {
  form: FormInstance;
  handleBack: () => void;
  setStep: (step: MainSteps) => void;
}

export const ContactInfoStep: React.FC<IContactInfoStep> = ({
  form,
  handleBack,
  setStep,
}) => {
  const { t } = useTranslation();

  const onContactInfoStepNext = () => {
    form.validateFields().then(() => {
      setStep(MainSteps.COMMENT);
    });
  };

  return (
    <>
      <div>
        <SectionHeading
          className={"main-heading"}
          title={t("describe_task")}
          h1Heading
        />
        <div className={"main-input-text"}>{t("your_contact_info")}</div>
      </div>
      <div className={"step-two-inputs-wrapper"}>
        {/*<Form.Item*/}
        {/*  name={"name"}*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*      message: <ValidationText message={t("field_is_required")} />,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <CustomInput className="step-two-input" placeholder={t("name")} />*/}
        {/*</Form.Item>*/}
        <Form.Item
          name={"phone"}
          rules={[
            {
              required: true,
              message: <ValidationText message={t("field_is_required")} />,
            },
            {
              pattern: /^5[0-9]{8}/,
              message: <ValidationText message={t("incorrect_number")} />,
            },
          ]}
        >
          <CustomInput
            prefix={<div className={"phone-prefix"}>+995</div>}
            id="gtm-task-flow-contact-info-phone-input"
            className="step-two-input gtm-task-flow-contact-info-phone-input"
            type={"tel"}
            onChange={(e: any) => {
              if (e.target.value?.length > 9) {
                form.setFieldsValue({ phone: e.target.value.slice(0, 9) });
                form.validateFields();
              }
            }}
          />
        </Form.Item>
      </div>
      <div className={"step-two-buttons-wrapper"}>
        <BackButton
          onClick={handleBack}
          id={"gtm-task-flow-contact-info-back-button"}
          className={"gtm-task-flow-contact-info-back-button"}
        />
        <CustomButton
          suffixIcon={<ArrowSvg />}
          onClick={onContactInfoStepNext}
          id={"gtm-task-flow-contact-info-next-button"}
          className={"gtm-task-flow-contact-info-next-button"}
        >
          {t("next")}
        </CustomButton>
      </div>
    </>
  );
};
