import React, { useState } from "react";
import "./CancellationPolicy.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CloseSvg } from "../../assets/svg";
import { routes } from "../../configs/routeConfigs";
import { CustomModal } from "../../components";

export const CancellationPolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  return (
    <CustomModal
      open={modalOpen}
      className={"cancellation-policy-modal"}
      title={t("task_cancellation_policy")}
      footer={<></>}
      closeIcon={<CloseSvg />}
      onCancel={() => setModalOpen(false)}
      afterClose={() => {
        navigate(routes.home);
      }}
      maskClosable
      destroyOnClose
    >
      <div className={"heading bold"}>
        {t("cancellation_section_heading_1")}
      </div>

      <div className={"divider"} />

      <div className={"bold underlined"}>
        {t("cancellation_subheading_1_section_1")}
      </div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={1}>
        {Array.from(Array(7)).map((value, i) => {
          return (
            <li key={`list_item_${i + 1}`} className={"ordered-list-item"}>
              {t(`cancellation_list_item_${i + 1}`)}
            </li>
          );
        })}
      </ol>

      <div className={"divider"} />

      <div className={"bold underlined"}>
        {t("cancellation_subheading_2_section_1")}
      </div>

      <div className={"divider"} />

      <ol className={"ordered-list"} start={8}>
        {Array.from(Array(1)).map((value, i) => {
          return (
            <li key={`list_item_${i + 8}`} className={"ordered-list-item"}>
              {t(`cancellation_list_item_${i + 8}`)}
            </li>
          );
        })}
      </ol>
    </CustomModal>
  );
};
