import React from "react";
import "./SectionHeading.scss";

interface ISectionHeading {
  title: string;
  className?: string;
  h1Heading?: boolean;
}

export const SectionHeading: React.FC<ISectionHeading> = ({
  title,
  className,
  h1Heading,
}) => {
  return (
    <>
      {h1Heading ? (
        <h1 className={`section-heading ${className || ""}`}>{title}</h1>
      ) : (
        <h2 className={`section-heading ${className || ""}`}>{title}</h2>
      )}
    </>
  );
};
