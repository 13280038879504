import React from "react";

export const GooglePlaySvg = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.710327" width="64" height="64" rx="16" fill="#D3DAE1" />
      <g clipPath="url(#clip0_1476_13323)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.571 44.3743L32.102 33.8733L35.814 37.5743L23.295 44.5153C22.819 44.7793 22.236 44.7753 21.763 44.5043L21.571 44.3743ZM31.04 32.8143L21 42.8253V22.8033L31.04 32.8143ZM37.314 28.6773L42.219 31.3963C42.701 31.6643 43 32.1663 43 32.7103C43 33.2543 42.701 33.7563 42.219 34.0243L37.18 36.8173L33.165 32.8143L37.314 28.6773ZM21.46 21.1433C21.55 21.0563 21.651 20.9803 21.763 20.9163C22.236 20.6453 22.819 20.6413 23.295 20.9053L35.948 27.9203L32.102 31.7553L21.46 21.1433Z"
          fill="#2C3642"
        />
      </g>
      <defs>
        <clipPath id="clip0_1476_13323">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(20 20.7103)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
