import axios, { AxiosRequestConfig } from "axios";
import { getApiUrl } from "../config";
import {
  getLanguageHeader,
  getLocalStorageLanguage,
  getToken,
  removeToken,
} from "../utils/helpers";
import { Languages } from "../i18n/resources";

export const apiClient = axios.create({
  baseURL: getApiUrl(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": "ka-GE",
    "Application-Type": "Client",
    "FCM-Token": "DeviceId",
  },
});

export const insertToken = (config: AxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
};

const insertLanguage = (config: AxiosRequestConfig) => {
  const language = getLocalStorageLanguage() as Languages;

  if (language) {
    const languageHeader = getLanguageHeader(language);

    config.headers = {
      ...config.headers,
      "Accept-Language": languageHeader,
    };
  }
  return config;
};

apiClient.interceptors.request.use(insertToken, (error) =>
  Promise.reject(error),
);

apiClient.interceptors.request.use(insertLanguage, (error) =>
  Promise.reject(error),
);

apiClient.interceptors.response.use(
  (resp) => resp,
  (err) => {
    // console.log(err);

    if (err.response.status.toString() === "401") {
      removeToken();
    }
    //remove token from localstorage
    //redirect to login

    throw err;
  },
);
