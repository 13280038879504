import { Form, FormInstance, message } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { ArrowSvg } from "../../../assets/svg";
import {
  CustomButton,
  CustomInput,
  CustomSelect,
  SectionHeading,
  ValidationText,
} from "../../../components";
import { useGetCities } from "../../../service/catalog/useGetCities";
import { BackButton } from "../components/BackButton/BackButton";
import { getToken } from "../../../utils/helpers";
import { MainSteps } from "../SendTask";
import { useGetClient } from "../../../service/client/useGetClient";
import {
  SenderTypes,
  useCreateTask,
} from "../../../service/task/useCreateTask";
import TagManager from "react-gtm-module";

interface ILocationStep {
  handleBack: () => void;
  form: FormInstance;
  setStep: (step: MainSteps) => void;
}

export const LocationStep: React.FC<ILocationStep> = ({
  handleBack,
  form,
  setStep,
}) => {
  const { t } = useTranslation();
  const { isLoading, data: cities } = useGetCities();

  const { data: client } = useGetClient({
    enabled: !!getToken(),
  });
  const { mutate: createTask, isLoading: createTaskLoading } = useCreateTask();

  const onCreateTaskSuccess = () => {
    setStep(MainSteps.FINAL);
  };

  const onLocationStepNext = () => {
    form
      .validateFields()
      .then(() => {
        const { city: cityId, address } = form.getFieldsValue([
          "city",
          "address",
        ]);
        TagManager.dataLayer({
          dataLayer: {
            event: "select_location",
            country: "GE",
            city_id: cityId,
            city_name: cities?.find(
              (city) => city.id.toString() === cityId.toString(),
            )?.name,
            address: address,
          },
        });

        //if user is logged in
        if (getToken() && client) {
          //create task
          const fieldValues = form.getFieldsValue(true);

          createTask(
            {
              Title: t("task_from_web"),
              ClientId: client.id,
              Address: fieldValues?.address,
              CityId: fieldValues?.cityId,
              Description: fieldValues?.description,
              WemplerTaskSenderType: SenderTypes.Web,
              // Files: [],
            },
            {
              onSuccess: onCreateTaskSuccess,
              onError: () => message.error(t("error_text")),
            },
          );
        } else {
          //go to phone number step
          setStep(MainSteps.CONTACT_INFO);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div>
        <SectionHeading
          className={"main-heading"}
          title={t("describe_task")}
          h1Heading
        />
        <div className={"main-input-text"}>{t("your_address")}</div>
      </div>
      <div className={"step-three-inputs-wrapper"}>
        <Form.Item
          name={"city"}
          rules={[
            {
              required: true,
              message: <ValidationText message={t("field_is_required")} />,
            },
          ]}
        >
          <CustomSelect
            placeholder={t("choose_city")}
            options={cities}
            loading={isLoading}
            id={"gtm-task-flow-city-selector"}
            className={"gtm-task-flow-city-selector"}
          />
        </Form.Item>
        <Form.Item
          name={"address"}
          rules={[
            {
              required: true,
              message: <ValidationText message={t("field_is_required")} />,
            },
          ]}
        >
          <CustomInput
            placeholder={t("address")}
            id={"gtm-task-flow-address-input"}
            className={"gtm-task-flow-address-input"}
          />
        </Form.Item>
      </div>
      <div className={"step-three-buttons-wrapper"}>
        <BackButton
          onClick={handleBack}
          id={"gtm-task-flow-location-back-button"}
          className={"gtm-task-flow-location-back-button"}
        />
        <CustomButton
          suffixIcon={<ArrowSvg />}
          onClick={onLocationStepNext}
          loading={createTaskLoading}
          id={"gtm-task-flow-location-next-button"}
          className={"gtm-task-flow-location-next-button"}
        >
          {t("next")}
        </CustomButton>
      </div>
    </>
  );
};
