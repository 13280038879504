import React from "react";
import "./DoerInstructions.scss";
import { InstructionItem } from "../../../../components";
import { CallSvg, MessagesSvg, PersonCheckedSvg } from "../../../../assets/svg";
import { useTranslation } from "react-i18next";
import { Instruction } from "../../../HowItWorks/HowWemplerWorks/ClientInstructions/ClientInstructions";

export const DoerInstructions = () => {
  const { t } = useTranslation();

  const employeeInstructions: Instruction[] = [
    {
      id: 1,
      step: "01",
      text: t("employee_instruction_one"),
      icon: <CallSvg />,
    },
    {
      id: 2,
      step: "02",
      text: t("employee_instruction_two"),
      icon: <MessagesSvg />,
    },
    {
      id: 3,
      step: "03",
      text: t("employee_instruction_three"),
      icon: <PersonCheckedSvg fill={"#06D672"} />,
    },
  ];
  return (
    <div className={"doer-instructions-wrapper"}>
      <div className={"doer-instructions-heading"}>{t("if_employee")}</div>
      <div className={"doer-instructions-steps-wrapper"}>
        {employeeInstructions.map((item) => {
          return (
            <InstructionItem
              key={item.id}
              icon={item.icon}
              text={item.text}
              step={item.step}
            />
          );
        })}
      </div>
    </div>
  );
};
