import { GeneralResponseType } from "../types";
import { apiClient } from "../apiClient";
import { useMutation } from "@tanstack/react-query";

export enum Roles {
  Admin = 1,
  Doer = 2,
  Client = 3,
}

type ClientRegisterReq = {
  hash: string;
  code: string;
  firstName: string;
  lastName: string;
  cityId: number;
  phoneNumber: string;
  role: Roles;
};

type ClientRegisterData = {
  accessToken: string;
  refreshToken: string;
};

export type ClientRegisterResp = GeneralResponseType<ClientRegisterData>;

export const clientRegister = (
  data: ClientRegisterReq,
): Promise<ClientRegisterResp> =>
  apiClient.post("/client/register", data).then((resp) => resp.data);

export const useClientRegister = (
  onSuccess?: (data: ClientRegisterResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(clientRegister, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
