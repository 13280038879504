import { useSearchParams } from "react-router-dom";
import { Lang, Languages } from "../i18n/resources";
import { getLocalStorageLanguage } from "../utils/helpers";

export const useLanguageParams = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    lang: getLocalStorageLanguage() ?? Languages.KA,
  });

  return {
    language: searchParams.get("lang"),
    setLanguage: (value: Lang) => {
      searchParams.set("lang", value);
      setSearchParams(searchParams);
    },
  };
};
