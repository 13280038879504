import React from "react";

export const MessageSvg = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="32" cy="32" r="32" fill="#EF3653" />
      <path
        d="M30.6667 21.3333H26.4C24.1598 21.3333 23.0397 21.3333 22.184 21.7693C21.4314 22.1528 20.8195 22.7647 20.436 23.5174C20 24.373 20 25.4931 20 27.7333V34.6667C20 35.9066 20 36.5266 20.1363 37.0353C20.5062 38.4156 21.5844 39.4938 22.9647 39.8637C23.4734 40 24.0934 40 25.3333 40V43.114C25.3333 43.8245 25.3333 44.1797 25.479 44.3622C25.6056 44.5208 25.7977 44.6131 26.0007 44.6129C26.2342 44.6127 26.5116 44.3908 27.0663 43.9469L30.247 41.4024C30.8967 40.8826 31.2216 40.6228 31.5833 40.4379C31.9043 40.274 32.2459 40.1541 32.599 40.0817C32.9969 40 33.4129 40 34.245 40H36.2667C38.5069 40 39.627 40 40.4826 39.564C41.2353 39.1805 41.8472 38.5686 42.2307 37.816C42.6667 36.9603 42.6667 35.8402 42.6667 33.6V33.3333M42.8284 21.1716C44.3905 22.7337 44.3905 25.2663 42.8284 26.8284C41.2663 28.3905 38.7337 28.3905 37.1716 26.8284C35.6095 25.2663 35.6095 22.7337 37.1716 21.1716C38.7337 19.6095 41.2663 19.6095 42.8284 21.1716Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
