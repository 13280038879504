import React from "react";

export const FacebookSvg = ({ ...props }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.832 12.3556C22.832 6.79892 18.3554 2.29492 12.832 2.29492C7.3087 2.29492 2.83203 6.79892 2.83203 12.3556C2.83203 17.3783 6.48803 21.5403 11.2694 22.2949V15.2643H8.7307V12.3549H11.2694V10.1389C11.2694 7.61759 12.762 6.22426 15.0467 6.22426C16.14 6.22426 17.2854 6.42092 17.2854 6.42092V8.89692H16.0234C14.7814 8.89692 14.3947 9.67292 14.3947 10.4689V12.3556H17.168L16.7247 15.2636H14.3947V22.2949C19.176 21.5403 22.832 17.3783 22.832 12.3556Z"
        fill="white"
      />
    </svg>
  );
};
