import React from "react";

export const AddressSvg = ({ ...props }) => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="24" cy="24.7705" r="24" fill="#A0ACBA" />
      <path
        d="M17 27.0569C15.1486 27.8736 14 29.0117 14 30.2705C14 32.7558 18.4772 34.7705 24 34.7705C29.5228 34.7705 34 32.7558 34 30.2705C34 29.0117 32.8514 27.8736 31 27.0569M30 20.7705C30 24.8342 25.5 26.7705 24 29.7705C22.5 26.7705 18 24.8342 18 20.7705C18 17.4568 20.6863 14.7705 24 14.7705C27.3137 14.7705 30 17.4568 30 20.7705ZM25 20.7705C25 21.3228 24.5523 21.7705 24 21.7705C23.4477 21.7705 23 21.3228 23 20.7705C23 20.2182 23.4477 19.7705 24 19.7705C24.5523 19.7705 25 20.2182 25 20.7705Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
