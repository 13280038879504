import React from "react";
import { Contact } from "./Contact/Contact";
import { IfYouWantToHelp } from "./IfYouWantToHelp/IfYouWantToHelp";

export const BecomeDoer = () => {
  return (
    <section id={"become-doer"}>
      <Contact />
      <IfYouWantToHelp />
    </section>
  );
};
