import React from "react";

export const StatThreeSvg = ({ ...props }) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="48" cy="48" r="48" fill="#F9F9F9" />
      <path
        d="M56 32C57.8599 32 58.7899 32 59.5529 32.2044C61.6235 32.7592 63.2408 34.3765 63.7956 36.4471C64 37.2101 64 38.1401 64 40V58.4C64 61.7603 64 63.4405 63.346 64.7239C62.7708 65.8529 61.8529 66.7708 60.7239 67.346C59.4405 68 57.7603 68 54.4 68H41.6C38.2397 68 36.5595 68 35.2761 67.346C34.1471 66.7708 33.2292 65.8529 32.654 64.7239C32 63.4405 32 61.7603 32 58.4V40C32 38.1401 32 37.2101 32.2044 36.4471C32.7592 34.3765 34.3765 32.7592 36.4471 32.2044C37.2101 32 38.1401 32 40 32M42 54L46 58L55 49M43.2 36H52.8C53.9201 36 54.4802 36 54.908 35.782C55.2843 35.5903 55.5903 35.2843 55.782 34.908C56 34.4802 56 33.9201 56 32.8V31.2C56 30.0799 56 29.5198 55.782 29.092C55.5903 28.7157 55.2843 28.4097 54.908 28.218C54.4802 28 53.9201 28 52.8 28H43.2C42.0799 28 41.5198 28 41.092 28.218C40.7157 28.4097 40.4097 28.7157 40.218 29.092C40 29.5198 40 30.0799 40 31.2V32.8C40 33.9201 40 34.4802 40.218 34.908C40.4097 35.2843 40.7157 35.5903 41.092 35.782C41.5198 36 42.0799 36 43.2 36Z"
        stroke="#06d672"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
