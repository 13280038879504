import { apiClient } from "../apiClient";
import { useQuery } from "@tanstack/react-query";
import { GeneralResponseType, Option } from "../types";
import { UseQueryOptions } from "@tanstack/react-query/src/types";

type TaskDoer = {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  aboutMe: string;
  reviews: [
    {
      id: string;
      rating: number;
      comment: string;
      commenterName: string;
      commenterAvatar: string;
    },
  ];
  isTrusted: boolean;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
};

export type Task = {
  id: string;
  adminId: string;
  taskStatus: number;
  title: string;
  description: string;
  clientId: string;
  clientName: string;
  address: string;
  city: Option;
  internalComment: string;
  proposedStartTime: number;
  proposedDuration: number;
  doer: TaskDoer;
  price: number;
  transactionId: string;
  transactionUrl: string;
  actualStartTime: number;
  cancellationReason: {
    id: number;
    name: string;
    isTerminating: boolean;
  };
  isDirectTransfer: boolean;
  images: string[];
  createdTimestamp: string;
};

type GetTaskResp = GeneralResponseType<Task>;

export const getTask = (id?: number | string): Promise<GetTaskResp> =>
  apiClient.get(`/task/${id}`).then((resp) => resp.data);

export const useGetTask = (
  id?: number | string,
  options?: UseQueryOptions<GetTaskResp>,
) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["task"],
    queryFn: () => getTask(id),
    enabled: false,
    ...options,
  });
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
    refetch,
  };
};
