import React from "react";

export const GooglePlayOutlineSvg = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.04228 15.7756L8.06285 8.77508L10.5375 11.2424L2.1916 15.8696C1.87427 16.0456 1.48561 16.043 1.17028 15.8623L1.04228 15.7756ZM7.35486 8.06909L0.661621 14.743V1.39518L7.35486 8.06909V8.06909ZM11.5375 5.31113L14.8074 7.12377C15.1288 7.30243 15.3281 7.63709 15.3281 7.99976C15.3281 8.36242 15.1288 8.69708 14.8074 8.87574L11.4481 10.7377L8.77151 8.06909L11.5375 5.31113V5.31113ZM0.968284 0.288528C1.02828 0.230529 1.09562 0.179863 1.17028 0.137197C1.48561 -0.0434673 1.87427 -0.0461339 2.1916 0.129864L10.6268 4.80647L8.06285 7.3631L0.968284 0.288528Z"
        fill="#2C3642"
      />
    </svg>
  );
};
