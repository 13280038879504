import { GeneralResponseType } from "../types";
import { apiClient } from "../apiClient";
import { useMutation } from "@tanstack/react-query";
import { UploadFile } from "antd";

export type ClientAvatarUpdateReq = {
  Files: UploadFile[];
};

type ClientAvatarUpdateData = {
  url: string;
};

export type ClientAvatarUpdateResp =
  GeneralResponseType<ClientAvatarUpdateData>;

export const clientAvatarUpdate = (
  data: ClientAvatarUpdateReq,
): Promise<ClientAvatarUpdateResp> =>
  apiClient
    .post("/client/avatar", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp) => resp.data);

export const useClientAvatarUpdate = (
  onSuccess?: (data: ClientAvatarUpdateResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate, mutateAsync } = useMutation(clientAvatarUpdate, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
    mutateAsync,
  };
};
