import React from "react";
import { Description } from "./Description/Description";

export const AboutUs = () => {
  return (
    <section id={"about-us"}>
      <Description />
    </section>
  );
};
