import React from "react";

export const PersonSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98712 11.7885C3.7641 11.7885 1.01172 12.2758 1.01172 14.2273C1.01172 16.1789 3.74664 16.6837 6.98712 16.6837C10.2101 16.6837 12.9617 16.1956 12.9617 14.2448C12.9617 12.294 10.2276 11.7885 6.98712 11.7885Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.98741 9.00486C9.10249 9.00486 10.8168 7.28978 10.8168 5.1747C10.8168 3.05962 9.10249 1.34534 6.98741 1.34534C4.87233 1.34534 3.15725 3.05962 3.15725 5.1747C3.15011 7.28264 4.85328 8.99772 6.96042 9.00486H6.98741Z"
        stroke="white"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
