import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CloseSvg } from "../../assets/svg";
import { routes } from "../../configs/routeConfigs";
import { CustomButton, CustomModal } from "../../components";
import {
  TaskPayStatus,
  useTaskPayStatus,
} from "../../service/task/useTaskPayStatus";
import Success from "../../assets/json/Success.json";
import Loading from "../../assets/json/Loading.json";
import Lottie from "lottie-react";
import "./PaymentStatus.scss";
import { SlashCircleSvg } from "../../assets/svg/SlashCircleSvg";
import { PendingClockSvg } from "../../assets/svg/PendingClockSvg";
import TagManager from "react-gtm-module";
import { Task, useGetTask } from "../../service/task/useGetTask";
import { ValueOf } from "../../utils/type-helpers";

const PAY_STATUSES = {
  SUCCESS: "success",
  FAILURE: "failure",
  PENDING: "pending",
} as const;

type PayStatus = ValueOf<typeof PAY_STATUSES>;

export const PaymentStatus = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const { id } = useParams();
  const {
    refetch: refetchTaskPayStatus,
    isLoading: isTaskPayStatusLoading,
    isError: isTaskPayStatusError,
  } = useTaskPayStatus(id);
  const { refetch: refetchTask } = useGetTask(id);
  const [payStatus, setPayStatus] = useState<number | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getTaskPayStatus();
    }
  }, [id]);

  useEffect(() => {
    if (payStatus) {
      appendQueryParam(payStatus);
    } else {
      removeQueryParam();
    }
  }, [payStatus]);

  const getStatusName = (status: number): PayStatus => {
    switch (status) {
      case 2:
        return PAY_STATUSES.FAILURE;
      case 3:
        return PAY_STATUSES.SUCCESS;
      default:
        return PAY_STATUSES.PENDING;
    }
  };

  const appendQueryParam = (value: number) => {
    const name = getStatusName(value);
    searchParams.set("status", name);
    setSearchParams(searchParams);
  };

  const removeQueryParam = () => {
    searchParams.delete("status");
    setSearchParams(searchParams);
  };

  const getTaskData = async (): Promise<Task | null> => {
    try {
      const taskData = await refetchTask();
      return taskData?.data?.data || null;
    } catch (error) {
      console.error("Error fetching task:", error);
      return null;
    }
  };

  const getTaskPayStatusData = async (): Promise<TaskPayStatus | null> => {
    try {
      const taskData = await refetchTaskPayStatus();
      return taskData?.data?.data || null;
    } catch (error) {
      console.error("Error fetching task pay status:", error);
      return null;
    }
  };

  const getTaskPayStatus = async () => {
    const payStatusData = await getTaskPayStatusData();
    const status = payStatusData?.status;

    if (status) {
      setPayStatus(status);
      if (status.toString() === "3") {
        const task = await getTaskData();
        TagManager.dataLayer({
          dataLayer: {
            event: "purchase",
            search_term: task?.description ?? "N/A",
            city_name: task?.city.name ?? "N/A",
            price: task?.price ?? "N/A",
            currency: "GEL",
            task_id: id,
          },
        });
      }
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <CustomModal
      open={modalOpen}
      className={"payment-status-modal"}
      title={t("task")}
      footer={false}
      closeIcon={<CloseSvg />}
      onCancel={handleCancel}
      afterClose={() => {
        navigate(routes.home);
      }}
      maskClosable
      destroyOnClose
    >
      <div>
        {isTaskPayStatusLoading && (
          <div className={"payment-status-loading-wrapper"}>
            <div className={"payment-status-modal-content-wrapper"}>
              <div className={"payment-status-modal-content"}>
                <Lottie
                  animationData={Loading}
                  loop={true}
                  style={{ height: "96px" }}
                />
                <div className={"payment-status-text"}>{t("please_wait")}</div>
                <div className={"payment-status-subtext"}>
                  {t("payment_verification_pending")}
                </div>
              </div>
            </div>
          </div>
        )}
        {(payStatus === 1 || isTaskPayStatusError) && (
          <div>
            <div className={"payment-status-modal-content-wrapper"}>
              <div className={"payment-status-modal-content"}>
                <div className={"payment-status-logo"}>
                  <PendingClockSvg />
                </div>
                <div className={"payment-status-text"}>
                  {t("payment_pending")}
                </div>
                <div className={"payment-status-subtext"}>
                  {t("please_check_again")}
                </div>
              </div>
            </div>
            <div className={"payment-status-footer-button-wrapper"}>
              <CustomButton
                id={"gtm-payment-status-check-again-button"}
                className={"gtm-payment-status-check-again-button"}
                onClick={getTaskPayStatus}
              >
                {t("check_again")}
              </CustomButton>
            </div>
          </div>
        )}
        {payStatus === 2 && (
          <div>
            <div className={"payment-status-modal-content-wrapper"}>
              <div className={"payment-status-modal-content"}>
                <div className={"payment-status-logo"}>
                  <SlashCircleSvg />
                </div>
                <div className={"payment-status-text"}>
                  {t("payment_unsuccessful")}
                </div>
                <div className={"payment-status-subtext"}>
                  {t("please_try_again")}
                </div>
              </div>
            </div>
            <div className={"payment-status-footer-button-wrapper"}>
              <CustomButton
                id={"gtm-payment-status-try-again-button"}
                className={"gtm-payment-status-try-again-button"}
                onClick={getTaskPayStatus}
              >
                {t("try_again")}
              </CustomButton>
            </div>
          </div>
        )}
        {payStatus === 3 && (
          <div>
            <div className={"payment-status-modal-content-wrapper"}>
              <div className={"payment-status-modal-content"}>
                <Lottie
                  animationData={Success}
                  loop={false}
                  style={{ height: "96px" }}
                />
                <div className={"payment-status-text"}>
                  {t("payment_successful")}
                </div>
              </div>
            </div>
            <div className={"payment-status-footer-button-wrapper"}>
              <CustomButton
                id={"gtm-payment-status-finish-button"}
                className={"gtm-payment-status-finish-button"}
                onClick={handleCancel}
              >
                {t("finish")}
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    </CustomModal>
  );
};
