import React from "react";

export const DrawerSvg = ({ ...props }) => {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 8.9436H25M1 0.943604H25M1 16.9436H17"
        stroke="#EF3653"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
