import { useMutation } from "@tanstack/react-query";
import { GeneralResponseType } from "../types";
import { apiClient } from "../apiClient";

type SendOtpCodeReq = {
  phoneNumber: string;
};

export type Hash = string | null;

type HashData = {
  hash: Hash;
};

export type SendOtpCodeResp = GeneralResponseType<HashData>;

export const sendOtpCode = (data: SendOtpCodeReq): Promise<SendOtpCodeResp> =>
  apiClient.post("/otp/gosmsOtpRequest", data).then((resp) => resp.data);

export const useSendOtpCode = (
  onSuccess?: (data: SendOtpCodeResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(sendOtpCode, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
