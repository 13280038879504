import { apiClient } from "../apiClient";
import { useQuery } from "@tanstack/react-query";
import { GeneralResponseType, Option, TaskStatuses } from "../types";
import { UseQueryOptions } from "@tanstack/react-query/src/types";

export type TaskData = {
  id: string;
  taskStatus: TaskStatuses;
  title: string;
  description: string;
  address: string;
  city: Option;
  proposedStartTime: number;
  proposedDuration: number;
  doerName: string;
  price: number;
  actualStartTime: number;
};

type GetClientTasksResp = GeneralResponseType<TaskData[]>;

export const getClientTasks = (): Promise<GetClientTasksResp> =>
  apiClient.get("/client/tasks").then((resp) => resp.data);

export const useGetClientTasks = (
  options?: UseQueryOptions<GetClientTasksResp>,
) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["client_tasks"],
    queryFn: getClientTasks,
    ...options,
  });
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
    refetch,
  };
};
