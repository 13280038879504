import React from "react";

export const CheckCircleSvg = ({ ...props }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.332 11.0857V12.0057C22.3308 14.1621 21.6325 16.2604 20.3414 17.9875C19.0502 19.7147 17.2353 20.9782 15.1674 21.5896C13.0995 22.201 10.8893 22.1276 8.8665 21.3803C6.84371 20.633 5.11669 19.2518 3.94299 17.4428C2.7693 15.6338 2.21182 13.4938 2.35371 11.342C2.49559 9.19029 3.32924 7.14205 4.73031 5.5028C6.13138 3.86354 8.02482 2.72111 10.1282 2.24587C12.2316 1.77063 14.4323 1.98806 16.402 2.86572M22.332 4L12.332 14.01L9.33203 11.01"
        stroke="#617585"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
