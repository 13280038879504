import React, { useEffect, useRef, useState } from "react";
import "./StatItem.scss";
import { useCountUp } from "react-countup";

interface IStatItem {
  statIcon: JSX.Element;
  statNumber: number;
  statSubText: string;
  color: string;
  withPlus?: boolean;
}

export const StatItem: React.FC<IStatItem> = ({
  statIcon,
  statNumber,
  statSubText,
  color,
  withPlus = false,
}) => {
  const countUpRef = useRef<any>();
  const statRef = useRef<any>();
  const { start } = useCountUp({
    ref: countUpRef,
    end: statNumber,
    startOnMount: false,
    formattingFn: (value: number) => value.toLocaleString(), //add comma separation
  });
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    if (isInView) start();
  }, [isInView]);

  const handleObserver = (entries: any) => {
    const target = entries[0];
    if (target.isIntersecting && !isInView) {
      setIsInView(true);
    } else if (!target.isIntersecting && isInView) {
      setIsInView(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (statRef.current) {
      observer.observe(statRef.current);
    }
    return () => observer.disconnect();
  }, [handleObserver]);

  return (
    <div data-aos="fade-up" className={"stat-item-container"} ref={statRef}>
      {statIcon && React.cloneElement(statIcon)}
      <div className={"stat-numbers-container"}>
        <div className={"stat-num-wrapper"}>
          <div ref={countUpRef} className={"stat-num"} style={{ color }} />
          {withPlus && (
            <div className={"stat-num-plus"} style={{ color }}>
              +
            </div>
          )}
        </div>
        <div className={"stat-sub-text"}>{statSubText}</div>
      </div>
    </div>
  );
};
