import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "antd/dist/antd.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import "./App.scss";
import initI18N from "./i18n";
import { TermsAndConditionsModal } from "./layouts";
import { Routes, Route } from "react-router-dom";
import { routes } from "./configs/routeConfigs";
import { PrivacyPolicyModal } from "./layouts/PrivacyPolicy/PrivacyPolicyModal";
import { CancellationPolicy } from "./layouts/CancellationPolicy/CancellationPolicy";
import { Faq } from "./layouts/FAQ/Faq";
import { PaymentStatus } from "./layouts/PaymentStatus/PaymentStatus";
import { MainComponent } from "./MainComponent";
import { Helmet } from "react-helmet-async";

initI18N();

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta name="apple-itunes-app" content="app-id=1644381619" />
        <meta name="google-play-app" content="app-id=ge.wempler.app.orderer" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<MainComponent />}>
            <Route path={routes.terms} element={<TermsAndConditionsModal />} />
            <Route path={routes.privacy} element={<PrivacyPolicyModal />} />
            <Route
              path={routes.cancellation}
              element={<CancellationPolicy />}
            />
            <Route path={routes.faq} element={<Faq />} />
            <Route path={routes.paymentStatus} element={<PaymentStatus />} />
          </Route>

          <Route path="*" element={<MainComponent />} />
        </Routes>
      </QueryClientProvider>
    </div>
  );
}

export default App;
