import React from "react";

export const RusFlagSvg = ({ ...props }) => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2529_237)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H21.3333V16H0V0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.33331H21.3333V16H0V5.33331Z"
          fill="#0039A6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10.6667H21.3333V16H0V10.6667Z"
          fill="#D52B1E"
        />
      </g>
      <defs>
        <clipPath id="clip0_2529_237">
          <rect width="21.3333" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
