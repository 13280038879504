import { apiClient } from "../apiClient";
import { useQuery } from "@tanstack/react-query";
import { GeneralResponseType } from "../types";
import { UseQueryOptions } from "@tanstack/react-query/src/types";

export type TaskPayStatus = {
  status: number;
};

type GetTaskPayStatusResp = GeneralResponseType<TaskPayStatus>;

export const getTaskPayStatus = (
  id?: number | string,
): Promise<GetTaskPayStatusResp> =>
  apiClient.get(`/task/pay/status/${id}`).then((resp) => resp.data);

export const useTaskPayStatus = (
  id?: number | string,
  options?: UseQueryOptions<GetTaskPayStatusResp>,
) => {
  const { data, isLoading, refetch, isError } = useQuery({
    queryKey: ["task-pay-status"],
    queryFn: () => getTaskPayStatus(id),
    enabled: false,
    ...options,
  });
  return {
    isLoading,
    isError,
    data: data?.data,
    status: data?.status,
    refetch,
  };
};
