import React from "react";

export const PersonCheckedSvg = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#EF3653" {...props} />
      <path
        d="M32.0013 36.6667H26.0013C24.1406 36.6667 23.2102 36.6667 22.4531 36.8963C20.7486 37.4134 19.4147 38.7473 18.8976 40.4518C18.668 41.2089 18.668 42.1393 18.668 44M37.3346 40L40.0013 42.6667L45.3346 37.3333M35.3346 26C35.3346 29.3137 32.6483 32 29.3346 32C26.0209 32 23.3346 29.3137 23.3346 26C23.3346 22.6863 26.0209 20 29.3346 20C32.6483 20 35.3346 22.6863 35.3346 26Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
