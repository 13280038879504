import React from "react";

export const AppStoreOutlineSvg = ({ ...props }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9168 11.738C13.3928 13.258 11.8242 15.9493 10.2082 15.9787C9.13616 15.9993 8.7915 15.3433 7.56616 15.3433C6.3415 15.3433 5.95816 15.9587 4.94483 15.9987C3.23016 16.0647 0.583496 12.114 0.583496 8.66867C0.583496 5.504 2.78883 3.93533 4.7155 3.90667C5.74883 3.888 6.72483 4.60333 7.35483 4.60333C7.9875 4.60333 9.17283 3.74333 10.4188 3.86933C10.9402 3.89133 12.4048 4.07933 13.3448 5.454C10.8508 7.082 11.2395 10.4867 13.9168 11.738ZM10.4355 0C8.5515 0.076 7.01416 2.05267 7.22883 3.68733C8.97016 3.82267 10.6408 1.87067 10.4355 0Z"
        fill="#2C3642"
      />
    </svg>
  );
};
