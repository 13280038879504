import React, { useEffect, useMemo, useState } from "react";
import "./Header.scss";
import { useTranslation } from "react-i18next";
import { CustomButton, LanguageSelect } from "../../components";
import { DrawerSvg, HomeSvg, PhoneSvg, WemplerSvg } from "../../assets/svg";
import { useWindowResize } from "../../hooks";
import { Drawer } from "./Drawer/Drawer";
import { languages } from "../../configs/globalConfigs";
import { Lang, Languages } from "../../i18n/resources";
import { AuthorizationModal } from "../AuthorizationModal/AuthorizationModal";
import { useQueryClient } from "@tanstack/react-query";
import { User } from "./User/User";
import { useLanguageParams } from "../../hooks/useLanguageParams";
import { isLang, setLocalStorageLanguage } from "../../utils/helpers";
import classNames from "classnames";

export type HeaderItem = {
  id: string;
  text: string;
  className: string;
};

export const Header = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language as Lang;
  const queryClient = useQueryClient();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [authorizationOpen, setAuthorizationOpen] = useState<boolean>(false);
  const { isXl, isLg } = useWindowResize();
  const { language, setLanguage } = useLanguageParams();

  useEffect(() => {
    if (isLang(language)) {
      i18n.changeLanguage(language);
      setLocalStorageLanguage(language);
    } else {
      setLanguage(Languages.KA);
    }
  }, [language]);

  const handleLanguageChange = (lang: Lang) => {
    setLanguage(lang);
    queryClient.invalidateQueries(["catalog-cities"]);
  };

  const listItems: HeaderItem[] = useMemo(() => {
    return [
      {
        id: "send-task",
        text: t("send_task"),
        className: "gtm-header-send-task-section-button",
      },
      {
        id: "about-us",
        text: t("about_us"),
        className: "gtm-header-about-us-section-button",
      },
      {
        id: "how-it-works",
        text: t("how_it_works"),
        className: "gtm-header-how-it-works-section-button",
      },
      {
        id: "contact",
        text: t("contact"),
        className: "gtm-header-contact-section-button",
      },
    ];
  }, [isXl, drawerOpen, t]);

  return (
    <header className={"header-container"}>
      <div className={"header-container-inner"}>
        <div className={"header-left-wrapper"}>
          <a
            href={"#about-us"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WemplerSvg className={"header-logo"} />
          </a>
          {isLg && (
            <ul className={"header-menu-wrapper"}>
              {listItems.map((item) => {
                return (
                  <li
                    id={item.className}
                    key={item.id}
                    className={classNames(
                      "header-menu-list-item",
                      item.className,
                    )}
                  >
                    <a
                      className="header-menu-list-item-link"
                      href={`#${item.id}`}
                    >
                      {item.text}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className={"header-right-wrapper"}>
          {isLg && (
            <>
              <CustomButton
                prefixIcon={<PhoneSvg />}
                id={"gtm-header-call-button"}
                className={`contact-us-button gtm-header-call-button`}
                onClick={() => (window.location.href = "tel:0322022010")}
              >
                {""}
              </CustomButton>
              <div className={"language-wrapper"}>
                <LanguageSelect
                  onChange={(value: Lang) => handleLanguageChange(value)}
                  defaultValue={lang}
                  options={languages}
                  value={lang}
                  id={"gtm-header-language-selector"}
                  className={"gtm-header-language-selector"}
                />
              </div>
            </>
          )}
          <User onAuthorizationClick={() => setAuthorizationOpen(true)} />
          {!isLg && (
            <DrawerSvg
              className={"drawer-wrapper"}
              onClick={() => setDrawerOpen(true)}
            />
          )}
          <Drawer
            drawerOpen={drawerOpen}
            setDrawerOpen={(open) => setDrawerOpen(open)}
            menuItems={listItems}
            handleLanguageChange={handleLanguageChange}
          />
          <AuthorizationModal
            open={authorizationOpen}
            handleCancel={() => setAuthorizationOpen(false)}
          />
        </div>
      </div>
    </header>
  );
};
