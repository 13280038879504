import React from "react";

export const StatOneSvg = ({ ...props }) => {
  return (
    <svg
      width="76"
      height="77"
      viewBox="0 0 76 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="38" cy="38.6001" r="38" fill="#F9F9F9" />
      <path
        d="M38.0013 47.3084H38.0171M31.9846 54.4334H44.018C45.7915 54.4334 46.6782 54.4334 47.3556 54.0882C47.9515 53.7846 48.4359 53.3002 48.7395 52.7044C49.0846 52.027 49.0846 51.1402 49.0846 49.3667V27.8334C49.0846 26.0599 49.0846 25.1731 48.7395 24.4958C48.4359 23.8999 47.9515 23.4155 47.3556 23.1119C46.6782 22.7667 45.7915 22.7667 44.018 22.7667H31.9846C30.2111 22.7667 29.3244 22.7667 28.647 23.1119C28.0512 23.4155 27.5667 23.8999 27.2631 24.4958C26.918 25.1731 26.918 26.0599 26.918 27.8334V49.3667C26.918 51.1402 26.918 52.027 27.2631 52.7044C27.5667 53.3002 28.0512 53.7846 28.647 54.0882C29.3244 54.4334 30.2111 54.4334 31.9846 54.4334ZM38.793 47.3084C38.793 47.7456 38.4385 48.1001 38.0013 48.1001C37.5641 48.1001 37.2096 47.7456 37.2096 47.3084C37.2096 46.8712 37.5641 46.5167 38.0013 46.5167C38.4385 46.5167 38.793 46.8712 38.793 47.3084Z"
        stroke="#EF3653"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
