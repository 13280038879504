import React from "react";

export const StatTwoSvg = ({ ...props }) => {
  return (
    <svg
      width="76"
      height="77"
      viewBox="0 0 76 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="38" cy="38.6001" r="38" fill="#F9F9F9" />
      <path
        d="M44.3346 25.0907C46.6807 26.2566 48.293 28.6776 48.293 31.4751C48.293 34.2726 46.6807 36.6936 44.3346 37.8595M47.5013 46.1469C49.8944 47.2298 52.0495 48.9947 53.8346 51.2668M22.168 51.2668C25.2499 47.3442 29.4342 44.9334 34.043 44.9334C38.6518 44.9334 42.836 47.3442 45.918 51.2668M41.168 31.4751C41.168 35.4101 37.978 38.6001 34.043 38.6001C30.1079 38.6001 26.918 35.4101 26.918 31.4751C26.918 27.5401 30.1079 24.3501 34.043 24.3501C37.978 24.3501 41.168 27.5401 41.168 31.4751Z"
        stroke="#37a6f7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
