import React from "react";

export const ErrorSvg = ({ ...props }) => {
  return (
    <svg
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M60.8315 40V60M60.8315 80H60.7815M10.8315 60C10.8315 32.3858 33.2173 10 60.8315 10C88.4458 10 110.832 32.3858 110.832 60C110.832 87.6142 88.4458 110 60.8315 110C33.2173 110 10.8315 87.6142 10.8315 60Z"
        stroke="#FF3100"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
