import { apiClient } from "../apiClient";
import { useQuery } from "@tanstack/react-query";
import { GeneralResponseType, Option } from "../types";

type GetCitiesResp = GeneralResponseType<Option[]>;

export const getCities = (): Promise<GetCitiesResp> =>
  apiClient.get("/catalog/cities").then((resp) => resp.data);

export const useGetCities = () => {
  const { data, isLoading } = useQuery(["catalog-cities"], getCities);
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
  };
};
