import React from "react";
import { Stats } from "./Stats/Stats";
import { HowWemplerWorks } from "./HowWemplerWorks/HowWemplerWorks";

export const HowItWorks = () => {
  return (
    <section id={"how-it-works"}>
      <HowWemplerWorks />
      <Stats />
    </section>
  );
};
