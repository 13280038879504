import "./BackButton.scss";
import React from "react";
import { Button, ButtonProps } from "antd";
import { BackArrowSvg } from "../../../../assets/svg/BackArrowSvg";

type BackButtonProps = ButtonProps & {
  className?: string;
  onClick?: () => void;
};

export const BackButton: React.FC<BackButtonProps> = ({
  className,
  onClick,
  ...rest
}) => {
  return (
    <Button
      className={`back-button ${className || ""}`}
      type={"default"}
      onClick={onClick}
      {...rest}
    >
      <BackArrowSvg />
    </Button>
  );
};
