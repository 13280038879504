import "./PersonalInfoStep.scss";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  CustomButton,
  CustomInput,
  CustomSelect,
  ValidationText,
} from "../../../components";
import { Checkbox, Col, Form, FormInstance, message, Row } from "antd";
import { useGetCities } from "../../../service/catalog/useGetCities";
import { Lang } from "../../../i18n/resources";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../configs/routeConfigs";
import {
  Hash,
  SendOtpCodeResp,
  useSendOtpCode,
} from "../../../service/otp/useSendOtpCode";
import { AuthSteps } from "../AuthorizationModal";
import { useWindowResize } from "../../../hooks";

type PersonalInfoStepProps = {
  form: FormInstance;
  phoneNumber: string;
  setHash: (hash: Hash) => void;
  setAuthorizationStep: (step: AuthSteps) => void;
};

export const PersonalInfoStep: React.FC<PersonalInfoStepProps> = ({
  form,
  phoneNumber,
  setHash,
  setAuthorizationStep,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language as Lang;
  const { isLg } = useWindowResize();

  const { data: cities, isLoading } = useGetCities();
  const { mutate: sendOtp, isLoading: sendOtpLoading } = useSendOtpCode();

  const onSendOtpSuccess = (data: SendOtpCodeResp) => {
    const hash = data.data.hash;
    if (hash) {
      setHash(hash);
      setAuthorizationStep(AuthSteps.OTP);
    }
  };

  const onPersonalInfoStepNext = () => {
    if (phoneNumber) {
      const phoneWithPrefix = `995${phoneNumber}`;
      sendOtp(
        {
          phoneNumber: phoneWithPrefix,
        },
        {
          onSuccess: onSendOtpSuccess,
          onError: () => message.error("error_text"),
        },
      );
    }
  };

  return (
    <>
      <div className={"auth-step-body-container"}>
        <div className={"auth-step-body auth-personal-info-step-wrapper"}>
          <div className={"personal-info-step-description-text"}>
            {t("input_contact_info_to_activate")}
          </div>
          <div className={"personal-info-step-fields-container"}>
            <Row
              gutter={[16, 16]}
              className={"personal-info-step-fields-name-wrapper"}
            >
              <Col span={isLg ? 12 : 24}>
                <Form.Item
                  name={"firstName"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <ValidationText message={t("field_is_required")} />
                      ),
                    },
                  ]}
                >
                  <CustomInput
                    placeholder={t("name")}
                    id={"gtm-authorization-personal-info-first-name-input"}
                    className={
                      "gtm-authorization-personal-info-first-name-input"
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={isLg ? 12 : 24}>
                <Form.Item
                  name={"lastName"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <ValidationText message={t("field_is_required")} />
                      ),
                    },
                  ]}
                >
                  <CustomInput
                    placeholder={t("last_name")}
                    id={"gtm-authorization-personal-info-last-name-input"}
                    className={
                      "gtm-authorization-personal-info-last-name-input"
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className={"personal-info-step-fields-city-wrapper"}>
              <Col span={24}>
                <Form.Item
                  name={"cityId"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <ValidationText message={t("field_is_required")} />
                      ),
                    },
                  ]}
                >
                  <CustomSelect
                    placeholder={t("choose_city")}
                    options={cities}
                    loading={isLoading}
                    popupClassName={"personal-info-step-city-dropdown"}
                    listHeight={130}
                    id={"gtm-authorization-personal-info-city-selector"}
                    className={"gtm-authorization-personal-info-city-selector"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={"agreeToTerms"}
                  rules={[
                    {
                      required: true,
                      transform: (value: boolean) => value || undefined,
                      type: "boolean",
                      message: (
                        <ValidationText message={t("field_is_required")} />
                      ),
                    },
                  ]}
                  className={"agree-icon-wrapper"}
                  valuePropName={"checked"}
                >
                  <Checkbox
                    id="gtm-authorization-personal-info-terms-checkbox"
                    className="agree-checkbox gtm-authorization-personal-info-terms-checkbox"
                  >
                    <div className={"terms-and-conditions-text-wrapper"}>
                      {t("agree_to_wemplers")}
                      <span
                        className={"link-text"}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(routes.privacy);
                        }}
                      >
                        {t("to_privacy_policy")}
                      </span>
                      {t("and")}
                      <span
                        className={"link-text"}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(routes.terms);
                        }}
                      >
                        {t("to_terms_and_conditions")}
                      </span>
                    </div>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className={"auth-footer-button-wrapper"}>
        <CustomButton
          onClick={() => {
            form.validateFields().then(onPersonalInfoStepNext);
          }}
          loading={sendOtpLoading}
          id={"gtm-authorization-personal-info-next-button"}
          className={"gtm-authorization-personal-info-next-button"}
        >
          {t("agree")}
        </CustomButton>
      </div>
    </>
  );
};
