import React from "react";
import "./InstructionItem.scss";

interface IInstructionItem {
  icon: JSX.Element;
  text: string;
  step: string;
}

export const InstructionItem: React.FC<IInstructionItem> = ({
  icon,
  text,
  step,
}) => {
  return (
    <div data-aos="zoom-in" className={"instruction-item-container"}>
      {icon && React.cloneElement(icon)}
      <div className={"instruction-item-text"}>{text}</div>
      <div className={"instruction-background-steps"}>{step}</div>
    </div>
  );
};
