import React, { PropsWithChildren } from "react";
import "./CustomModal.scss";
import { CloseSvg } from "../../assets/svg";
import { Modal, ModalProps } from "antd";

type CustomModalProps = ModalProps &
  PropsWithChildren<{
    className?: string;
    title?: string;
    onCancel?: () => void;
  }>;

export const CustomModal: React.FC<CustomModalProps> = ({
  title,
  className,
  onCancel,
  children,
  ...props
}) => {
  return (
    <Modal
      {...props}
      title={
        title ? (
          <div className={"custom-modal-title-wrapper"}>
            <div className={"custom-modal-title-text"}>{title}</div>
            <CloseSvg
              onClick={onCancel}
              className={"custom-modal-close-icon"}
            />
          </div>
        ) : null
      }
      onCancel={onCancel}
      className={`custom-modal ${className ?? ""}`}
      closeIcon={<></>}
      destroyOnClose
      centered
    >
      {children}
    </Modal>
  );
};
