import "./TasksDrawer.scss";
import { CustomDrawer } from "../../../../components/CustomDrawer/CustomDrawer";
import React from "react";
import { useTranslation } from "react-i18next";
import { TasksContent } from "../TasksModal/TasksContent/TasksContent";

type TasksDrawerProps = {
  open: boolean;
  onClose: () => void;
};

export const TasksDrawer: React.FC<TasksDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <CustomDrawer
      title={t("tasks")}
      closeIcon={false}
      placement={"bottom"}
      closable
      open={open}
      className={"tasks-drawer"}
      onClose={onClose}
    >
      <TasksContent open={open} />
    </CustomDrawer>
  );
};
