import {
  CustomButton,
  CustomInput,
  CustomModal,
  CustomSelect,
  ValidationText,
} from "../../../../components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row, Upload, UploadFile } from "antd";
import { RcFile } from "antd/es/upload";
import NoAvatar from "../../../../assets/png/NoAvatar.png";
import "./EditProfileModal.scss";
import { EditProfileSvg } from "../../../../assets/svg/EditProfileSvg";
import { useGetCities } from "../../../../service/catalog/useGetCities";
import { useGetClient } from "../../../../service/client/useGetClient";
import {
  ClientUpdateReq,
  useClientUpdate,
} from "../../../../service/client/useClientUpdate";
import { useClientAvatarUpdate } from "../../../../service/client/useClientAvatarUpdate";

type EditProfileModalProps = {
  open: boolean;
  onClose: () => void;
};

export const EditProfileModal: React.FC<EditProfileModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { data: cities, isLoading } = useGetCities();
  const { data: client, refetch: refetchClient } = useGetClient({
    refetchOnWindowFocus: false,
  });
  const {
    mutate: updateClient,
    mutateAsync: updateClientAsync,
    isLoading: updateClientLoading,
  } = useClientUpdate();
  const {
    mutateAsync: updateClientAvatarAsync,
    isLoading: updateClientAvatarLoading,
  } = useClientAvatarUpdate();

  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    if (client) {
      form.setFieldsValue({
        firstName: client.firstName,
        lastName: client.lastName,
        cityId: client.city.id,
      });
    }
  }, [client]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleProfilePictureChange = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
  };

  const onUpdateClient = (
    values: ClientUpdateReq & { avatar: { file: UploadFile } },
  ) => {
    const updateClientData = {
      firstName: values.firstName,
      lastName: values.lastName,
      cityId: values.cityId,
    };
    if (values.avatar) {
      Promise.all([
        updateClientAsync(updateClientData),
        updateClientAvatarAsync({
          Files: values.avatar.file ? [values.avatar.file] : [],
        }),
      ]).then(() => {
        refetchClient().then(() => onClose());
      });
    } else {
      updateClient(updateClientData, {
        onSuccess: onClose,
      });
    }
  };

  return (
    <CustomModal
      open={open}
      className={"edit-profile-modal"}
      title={t("edit")}
      footer={false}
      onCancel={onClose}
      maskClosable
    >
      <Form
        form={form}
        className={"edit-profile-form"}
        onFinish={onUpdateClient}
      >
        <div className={"edit-profile-form-content"}>
          <div className={"avatar-wrapper"}>
            <img
              src={previewImage ?? client?.avatar ?? NoAvatar}
              alt={"avatar"}
              className={"avatar-img"}
            />
            <Form.Item name={"avatar"} className={"edit-avatar-form-item"}>
              <Upload
                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                // listType="picture-card"
                // fileList={fileList}
                // onPreview={handlePreview}
                customRequest={() => null}
                accept="image/png, image/jpeg"
                multiple={false}
                onChange={(files) => {
                  handleProfilePictureChange(files.file);
                }}
                maxCount={1}
                showUploadList={false}
                className={"auth-upload"}
              >
                <div
                  id={"gtm-edit-profile-avatar-button"}
                  className={
                    "edit-profile-icon-wrapper gtm-edit-profile-avatar-button"
                  }
                >
                  <EditProfileSvg />
                </div>
              </Upload>
            </Form.Item>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name={"firstName"}
                rules={[
                  {
                    required: true,
                    message: (
                      <ValidationText message={t("field_is_required")} />
                    ),
                  },
                ]}
              >
                <CustomInput
                  placeholder={t("name")}
                  id={"gtm-edit-profile-first-name-input"}
                  className={"gtm-edit-profile-first-name-input"}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name={"lastName"}
                rules={[
                  {
                    required: true,
                    message: (
                      <ValidationText message={t("field_is_required")} />
                    ),
                  },
                ]}
              >
                <CustomInput
                  placeholder={t("last_name")}
                  id={"gtm-edit-profile-last-name-input"}
                  className={"gtm-edit-profile-last-name-input"}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name={"cityId"}
                rules={[
                  {
                    required: true,
                    message: (
                      <ValidationText message={t("field_is_required")} />
                    ),
                  },
                ]}
              >
                <CustomSelect
                  placeholder={t("choose_city")}
                  options={cities}
                  loading={isLoading}
                  popupClassName={"personal-info-step-city-dropdown"}
                  listHeight={130}
                  id={"gtm-edit-profile-city-selector"}
                  className={"gtm-edit-profile-city-selector"}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <CustomButton
                onClick={form.submit}
                loading={updateClientLoading || updateClientAvatarLoading}
                id={"gtm-edit-profile-save-changes-button"}
                className={"gtm-edit-profile-save-changes-button"}
              >
                {t("save_changes")}
              </CustomButton>
            </Col>
          </Row>
        </div>
      </Form>
    </CustomModal>
  );
};
