import OtpInput from "react18-input-otp";
import { message } from "antd";
import { ResendSvg } from "../../../assets/svg";
import { CustomButton } from "../../../components";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./OTPStep.scss";
import moment from "moment";
import { useTimer } from "react-timer-hook";
import { Hash, useSendOtpCode } from "../../../service/otp/useSendOtpCode";
import {
  useVerifyOtpCode,
  VerifyOtpCodeResp,
} from "../../../service/otp/useVerifyOtpCode";
import { setToken } from "../../../utils/helpers";
import {
  UserLoginResp,
  useUserLogin,
} from "../../../service/auth/useUserLogin";
import {
  ClientRegisterResp,
  Roles,
  useClientRegister,
} from "../../../service/client/useClientRegister";
import { ClientData, useGetClient } from "../../../service/client/useGetClient";
import TagManager from "react-gtm-module";

type OTPStepProps = {
  phoneNumber: string;
  hash: Hash;
  handleUserLoginSuccess?: (client: ClientData) => void;
  personalInfo?: {
    firstName: string;
    lastName: string;
    cityId: number;
  };
};

export const OTPStep: React.FC<OTPStepProps> = ({
  phoneNumber,
  hash,
  handleUserLoginSuccess,
  personalInfo,
}) => {
  const { t } = useTranslation();
  const ref = useRef<any>();
  const [otpValue, setOtpValue] = useState<string>("");
  const [otpError, setOtpError] = useState<boolean>(false);
  const [canResend, setCanResend] = useState<boolean>(false);
  const { refetch: refetchClient } = useGetClient({
    enabled: false,
  });

  const { mutate: sendOtp } = useSendOtpCode();

  const { isLoading: verifyOtpLoading, mutate: verifyOtp } = useVerifyOtpCode();

  const { mutate: userLogin, isLoading: userLoginLoading } = useUserLogin();
  const { mutate: clientRegister, isLoading: clientRegisterLoading } =
    useClientRegister();

  useEffect(() => {
    if (ref.current) {
      ref.current.focusInput(0);
    }
  }, [ref.current]);

  const getExpiryTimestamp = () => {
    return moment()
      .set("seconds", moment().seconds() + 60)
      .toDate();
  };

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: getExpiryTimestamp(),
    onExpire: () => setCanResend(true),
  });

  // const transformNumberToAsterisks = (
  //   phoneNumber: string,
  //   visibleChars = 3,
  // ) => {
  //   return (
  //     new Array(phoneNumber.length - visibleChars).join("*") +
  //     phoneNumber.slice(-Math.abs(visibleChars))
  //   );
  // };

  const restartTimer = () => {
    setCanResend(false);
    restart(getExpiryTimestamp());
  };

  const onUserLoginSuccess = (
    respData: UserLoginResp | ClientRegisterResp,
    newUser: boolean,
  ) => {
    const accessToken = respData.data.accessToken;
    if (accessToken) {
      setToken(accessToken);
      message.success(t("completed_auth_successfully"));
      refetchClient().then((clientData) => {
        if (clientData?.data?.data) {
          TagManager.dataLayer({
            dataLayer: {
              event: newUser ? "sign_up" : "login",
              user_id: clientData.data.data.id,
            },
          });
          handleUserLoginSuccess?.(clientData.data.data);
        }
      });
    }
  };

  const onVerifyOtpSuccess = (data: VerifyOtpCodeResp) => {
    if (data.data.isVerified && hash && phoneNumber) {
      const phoneWithPrefix = `995${phoneNumber}`;
      if (!personalInfo) {
        //log in user
        userLogin(
          {
            phoneNumber: phoneWithPrefix,
            hash: hash,
            code: otpValue,
          },
          {
            onSuccess: (respData: UserLoginResp) => {
              onUserLoginSuccess(respData, false);
            },
          },
        );
      } else {
        //register user
        clientRegister(
          {
            hash: hash,
            code: otpValue,
            firstName: personalInfo.firstName,
            lastName: personalInfo.lastName,
            cityId: personalInfo.cityId,
            phoneNumber: phoneWithPrefix,
            role: Roles.Client,
          },
          {
            onSuccess: (respData: ClientRegisterResp) => {
              onUserLoginSuccess(respData, true);
            },
          },
        );
        //@TODO onsuccess - close modal and show updated user state
      }
    }
  };

  const onOtpStepNext = () => {
    if (hash) {
      verifyOtp(
        {
          hash,
          code: otpValue,
          phoneNumber: `995${phoneNumber}`,
        },
        {
          onSuccess: onVerifyOtpSuccess,
          onError: () => setOtpError(true),
        },
      );
    }
  };

  return (
    <>
      <div className={"auth-step-body-container"}>
        <div className={"auth-step-body auth-otp-step-wrapper"}>
          <div className={"otp-code-sent-text"}>
            {t("code_was_sent_to", {
              number: phoneNumber, //transformNumberToAsterisks(phoneNumber)
            })}
          </div>
          <OtpInput
            ref={ref}
            containerStyle={"otp-input-wrapper"}
            focusStyle={"otp-input-focus"}
            inputStyle={"otp-input"}
            isInputNum
            shouldAutoFocus
            value={otpValue}
            onChange={(otp: string) => {
              setOtpValue(otp);
              setOtpError(false);
            }}
            numInputs={4}
            hasErrored={otpError}
            errorStyle={"otp-input-error"}
            id={"gtm-authorization-otp-input"}
            className={"gtm-authorization-otp-input"}
          />
          {otpError ? (
            <div className={"otp-input-error-text"}>{t("incorrect_code")}</div>
          ) : (
            <div className={"timer-wrapper"}>{`${minutes}:${seconds}`}</div>
          )}
          <div
            id={"gtm-authorization-otp-resend-button"}
            className={`send-new-code-wrapper gtm-authorization-otp-resend-button ${!canResend ? "disabled" : ""}`}
            onClick={() => {
              if (canResend && phoneNumber) {
                sendOtp(
                  { phoneNumber: `995${phoneNumber}` },
                  {
                    onSuccess: () => {
                      restartTimer();
                    },
                    onError: () => {
                      message.error(t("error_occurred"));
                    },
                  },
                );
              }
            }}
          >
            <div className={"send-new-code-text"}>{t("send_new_code")}</div>
            <ResendSvg />
          </div>
        </div>
      </div>
      <div className={"auth-footer-button-wrapper"}>
        <CustomButton
          onClick={() => {
            if (!otpError) {
              onOtpStepNext();
            }
          }}
          loading={
            verifyOtpLoading || userLoginLoading || clientRegisterLoading
          }
          id={"gtm-authorization-otp-next-button"}
          className={"gtm-authorization-otp-next-button"}
        >
          {t("agree")}
        </CustomButton>
      </div>
    </>
  );
};
