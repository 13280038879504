import React, { useEffect, useMemo, useRef, useState } from "react";
import "./SendTask.scss";
import { Carousel, Form } from "antd";
import { useTranslation } from "react-i18next";
import Delivery from "../../assets/png/DeliveryNew.png";
import DeliveryENG from "../../assets/png/DeliveryNewENG.png";
import DeliveryRU from "../../assets/png/DeliveryNewRU.png";
import Plumbing from "../../assets/png/PlumbingNew.png";
import PlumbingENG from "../../assets/png/PlumbingNewENG.png";
import PlumbingRU from "../../assets/png/PlumbingNewRU.png";
import Electricity from "../../assets/png/ElectricityNew.png";
import ElectricityENG from "../../assets/png/ElectricityNewENG.png";
import ElectricityRU from "../../assets/png/ElectricityNewRU.png";
import CarWash from "../../assets/png/CarWashNew.png";
import CarWashENG from "../../assets/png/CarWashNewENG.png";
import CarWashRU from "../../assets/png/CarWashNewRU.png";
import Cleaning from "../../assets/png/CleaningNew.png";
import CleaningENG from "../../assets/png/CleaningNewENG.png";
import CleaningRU from "../../assets/png/CleaningNewRU.png";
import DogWalking from "../../assets/png/DogWalkingNew.png";
import DogWalkingENG from "../../assets/png/DogWalkingNewENG.png";
import DogWalkingRU from "../../assets/png/DogWalkingNewRU.png";
import { useWindowResize } from "../../hooks";
import { sendHelpRequestData, TaskRequestData } from "../../service";
import { FinalStep } from "./FinalStep/FinalStep";
import { DescribeTaskStep } from "./DescribeTaskStep/DescribeTaskStep";
import { ContactInfoStep } from "./ContactInfoStep/ContactInfoStep";
import { LocationStep } from "./LocationStep/LocationStep";
import { CommentStep } from "./CommentStep/CommentStep";
import { usePaymentParams } from "../../hooks/usePaymentParams";
import TagManager from "react-gtm-module";
import { useGetPromotionWebImages } from "../../service/promotion/useGetPromotionWebImages";
import { Lang, Languages } from "../../i18n/resources";

export enum MainSteps {
  DESCRIBE_TASK,
  LOCATION,
  CONTACT_INFO,
  COMMENT,
  FINAL,
}

type Image = { key: string; en: string; ka: string; ru: string };
type CarouselImage = { key: string; src: string };

const images: Image[] = [
  {
    key: "delivery",
    en: DeliveryENG,
    ka: Delivery,
    ru: DeliveryRU,
  },
  {
    key: "plumbing",
    en: PlumbingENG,
    ka: Plumbing,
    ru: PlumbingRU,
  },
  {
    key: "electricity",
    en: ElectricityENG,
    ka: Electricity,
    ru: ElectricityRU,
  },
  {
    key: "car-wash",
    en: CarWashENG,
    ka: CarWash,
    ru: CarWashRU,
  },
  {
    key: "cleaning",
    en: CleaningENG,
    ka: Cleaning,
    ru: CleaningRU,
  },
  {
    key: "dog-walking",
    en: DogWalkingENG,
    ka: DogWalking,
    ru: DogWalkingRU,
  },
];

export const SendTask = () => {
  const { i18n } = useTranslation();

  const carouselRef = useRef<any>();
  const [form] = Form.useForm<TaskRequestData>();
  const [step, setStep] = useState<number>(0);
  // const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const { isLg } = useWindowResize();
  const { paymentStatus } = usePaymentParams();
  const { data: promotionImages } = useGetPromotionWebImages();
  const lang = i18n.language as Lang;

  useEffect(() => {
    if (paymentStatus) {
      setStep(MainSteps.FINAL);
    }
  }, [paymentStatus]);

  const carouselImages = useMemo((): CarouselImage[] => {
    return images.map((item) => {
      const src = lang ? item[lang] : item[Languages.KA];
      return {
        key: item.key,
        src,
      };
    });
  }, [lang]);

  const dynamicCarouselImages = useMemo((): CarouselImage[] => {
    if (promotionImages) {
      const mappedImages = promotionImages.map((image, index) => ({
        key: `promo-${index}`,
        src: image,
      }));
      return [...mappedImages, ...carouselImages];
    }
    return carouselImages;
  }, [promotionImages, carouselImages]);

  const handleNext = () => {
    if (step < stepComponents.length - 1) {
      setStep((prev) => prev + 1);
    } else {
      form.resetFields();
      setStep(0);
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    }
  };

  const stepComponents = useMemo(() => {
    return [
      <DescribeTaskStep
        key={MainSteps.DESCRIBE_TASK}
        handleNext={() => {
          form.validateFields().then(() => {
            const { description } = form.getFieldsValue(["description"]);
            TagManager.dataLayer({
              dataLayer: {
                event: "view_search_results",
                search_term: description,
              },
            });
            TagManager.dataLayer({
              dataLayer: {
                event: "form_start",
                search_term: description,
              },
            });
            handleNext();
          });
        }}
      />,
      <LocationStep
        key={MainSteps.LOCATION}
        form={form}
        handleBack={handleBack}
        setStep={setStep}
      />,
      <ContactInfoStep
        key={MainSteps.CONTACT_INFO}
        form={form}
        handleBack={handleBack}
        setStep={setStep}
      />,
      <CommentStep
        key={MainSteps.COMMENT}
        handleBack={handleBack}
        form={form}
        setStep={setStep}
      />,
      <FinalStep key={MainSteps.FINAL} handleNext={handleNext} />,
    ];
  }, [step, form]);

  const handleSubmit = () => {
    const values: TaskRequestData = form.getFieldsValue(true);
    console.log("values", values);
  };

  return (
    <>
      <section id={"send-task"} data-aos="fade-in" className={"main-container"}>
        <div className={"main-container-inner"}>
          <div className={"main-left-wrapper"}>
            <Form
              form={form}
              // onValuesChange={handleValuesChange}
              // preserve={true}
              onFinish={handleSubmit}
              className={"steps-form"}
            >
              {stepComponents[step]}
            </Form>
          </div>
          <div className={"main-right-wrapper"}>
            <Carousel
              className={"main-carousel"}
              draggable
              autoplay
              autoplaySpeed={5000}
              dots={false}
              arrows={false}
              slidesToShow={1}
              slidesToScroll={1}
              infinite={true}
              ref={carouselRef}
              fade
            >
              {dynamicCarouselImages.map((item) => {
                return (
                  <img
                    key={item.key}
                    className={`main-carousel-item ${item.key}`}
                    src={item.src}
                    alt={""}
                  />
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
    </>
  );
};
