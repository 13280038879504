import { Lang, Languages } from "../i18n/resources";
import { LanguageHeaders } from "../service/types";

export const setToken = (token: string, name = "wemp_token") => {
  localStorage.setItem(name, token);
};

export const getToken = (name = "wemp_token") => {
  return localStorage.getItem(name);
};

export const removeToken = (name = "wemp_token") => {
  localStorage.removeItem(name);
};

export const setLocalStorageLanguage = (language: string, name = "lang") => {
  localStorage.setItem(name, language);
};

export const getLocalStorageLanguage = (name = "lang") => {
  return localStorage.getItem(name);
};

export const setLocalStoragePromotionIds = (id: string) => {
  const previousIds = getLocalStoragePromotionIds();
  if (!previousIds.includes(id)) {
    localStorage.setItem("promotionIds", JSON.stringify([...previousIds, id]));
  }
};

export const getLocalStoragePromotionIds = () => {
  const promotionIds = localStorage.getItem("promotionIds");
  return promotionIds ? JSON.parse(promotionIds) : [];
};

export const getLanguageHeader = (language: Languages) => {
  switch (language) {
    case Languages.EN:
      return LanguageHeaders.EN;
    case Languages.RU:
      return LanguageHeaders.RU;
    default:
      return LanguageHeaders.KA;
  }
};

export const isLang = (value: string | null): value is Lang => {
  return value ? ["en", "ka", "ru"].includes(value) : false; //@TODO replace hardcoded values
};
