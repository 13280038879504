import React, { useMemo } from "react";
import "./TaskItem.scss";
import { TasksSvg } from "../../../../../assets/svg/TasksSvg";
import { TaskData } from "../../../../../service/client/useGetClientTasks";
import { TaskStatuses } from "../../../../../service/types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "antd";
import {
  PayTaskResp,
  useTaskPayment,
} from "../../../../../service/task/useTaskPayment";
import TagManager from "react-gtm-module";
import PrimeLogo from "../../../../../assets/png/Prime.png";

type TaskItemProps = {
  task: TaskData;
};

const statusMap: Record<TaskStatuses, string> = {
  [TaskStatuses.Created]: "created",
  [TaskStatuses.Assigned]: "assigned",
  [TaskStatuses.Accepted]: "accepted",
  [TaskStatuses.InProgress]: "in_progress",
  [TaskStatuses.Completed]: "completed",
  [TaskStatuses.Rejected]: "rejected",
  [TaskStatuses.Refunded]: "refunded",
};

export const TaskItem: React.FC<TaskItemProps> = ({ task }) => {
  const { t } = useTranslation();
  const { mutate: payTask, isLoading: payTaskLoading } = useTaskPayment();

  const isInProgress = useMemo(() => {
    return isTaskInProgress(task.taskStatus);
  }, [task]);

  const statusColor = useMemo(() => {
    if (isInProgress) {
      return "#F9C922";
    } else {
      return "#37A6F6";
    }
  }, [isInProgress]);

  const onPayClick = () => {
    payTask(
      {
        taskId: task.id,
        saveCard: false,
      },
      {
        onSuccess: (resp: PayTaskResp) => {
          const url = resp.data.paymentUrl;
          setTimeout(() => {
            window.open(url, "_self");
          });

          TagManager.dataLayer({
            dataLayer: {
              event: "begin_checkout",
              search_term: task.description,
              price: task.price,
              currency: "GEL",
              city_name: task.city.name,
              task_id: task.id,
            },
          });
        },
        onError: (err) => console.log("payment error", err),
      },
    );
  };

  return (
    <div className={"task-item-container"}>
      <div className={"task-item-left-wrapper"}>
        <div className={"task-item-icon-wrapper"}>
          <TasksSvg />
        </div>
        <div className={"task-description-wrapper"}>
          <div className={"task-text-wrapper"}>
            <div className={"task-item-text"}>{task.title}</div>
            <div className={"task-item-status"}>
              <div
                style={{ background: `${statusColor}` }}
                className={"task-item-status-indicator"}
              />
              <div className={"task-item-status-text"}>
                {statusMap[task.taskStatus]
                  ? t(statusMap[task.taskStatus])
                  : t("active")}
              </div>
            </div>
          </div>
          {isInProgress && (
            <div className={"task-item-insurance-wrapper"}>
              <img src={PrimeLogo} alt={"logo"} className={"insurance-img"} />
              <div className={"insurance-text"}>{t("insured_by_prime")}</div>
            </div>
          )}
        </div>
      </div>
      <div className={"task-item-right-wrapper"}>
        {isInProgress && (
          <Button
            id="gtm-pay-task-button"
            className={"task-pay gtm-pay-task-button"}
            loading={payTaskLoading}
            onClick={onPayClick}
          >
            {t("pay")}
          </Button>
        )}
        <div className={"task-date"}>{moment().format("DD.MM.YYYY")}</div>
      </div>
    </div>
  );
};

function isTaskInProgress(taskStatus: TaskStatuses): boolean {
  return (
    taskStatus === TaskStatuses.Assigned ||
    taskStatus === TaskStatuses.Accepted ||
    taskStatus === TaskStatuses.InProgress
  );
}
