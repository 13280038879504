import { apiClient } from "../apiClient";
import { useQuery } from "@tanstack/react-query";
import { GeneralResponseType } from "../types";

type GetPromotionAlertsResp = GeneralResponseType<string[]>;

export const getPromotionWebImages = (): Promise<GetPromotionAlertsResp> =>
  apiClient.get("/promotion/web-images").then((resp) => resp.data);

export const useGetPromotionWebImages = () => {
  const { data, isLoading } = useQuery(
    ["promotion-web-images"],
    getPromotionWebImages,
  );
  return {
    isLoading,
    data: data?.data,
    status: data?.status,
  };
};
