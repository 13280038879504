import React, { useState } from "react";
import "./Faq.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CloseSvg } from "../../assets/svg";
import { routes } from "../../configs/routeConfigs";
import { CustomModal } from "../../components";

export const Faq = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  return (
    <CustomModal
      open={modalOpen}
      className={"faq-modal"}
      title={t("frequently_asked_questions")}
      footer={<></>}
      closeIcon={<CloseSvg />}
      onCancel={() => setModalOpen(false)}
      afterClose={() => {
        navigate(routes.home);
      }}
      maskClosable
      destroyOnClose
    >
      <ul className={"unordered-list"}>
        <li className={"unordered-list-item bold"}>{t("faq_question_1")}</li>
      </ul>
      <div className={"unordered-list-item"}>{t("faq_answer_1_1")}</div>
      <div className={"unordered-list-item"}>{t("faq_answer_1_2")}</div>

      <div className={"divider"} />

      <ul className={"unordered-list"}>
        <li className={"unordered-list-item bold"}>{t("faq_question_2")}</li>
      </ul>
      <div className={"unordered-list-item"}>{t("faq_answer_2_1")}</div>
      <div className={"unordered-list-item"}>{t("faq_answer_2_2")}</div>
      <div className={"unordered-list-item"}>{t("faq_answer_2_3")}</div>

      <div className={"divider"} />

      <ul className={"unordered-list"}>
        <li className={"unordered-list-item bold"}>{t("faq_question_3")}</li>
      </ul>
      <div className={"unordered-list-item"}>{t("faq_answer_3")}</div>

      <div className={"divider"} />

      <ul className={"unordered-list"}>
        <li className={"unordered-list-item bold"}>{t("faq_question_4")}</li>
      </ul>
      <div className={"unordered-list-item"}>{t("faq_answer_4")}</div>

      <div className={"divider"} />

      <ul className={"unordered-list"}>
        <li className={"unordered-list-item bold"}>{t("faq_question_5")}</li>
      </ul>
      <div className={"unordered-list-item"}>{t("faq_answer_5")}</div>

      <div className={"divider"} />
    </CustomModal>
  );
};
