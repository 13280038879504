import React, { useState } from "react";
import { Form, FormInstance, message } from "antd";
import {
  CustomButton,
  CustomTextArea,
  SectionHeading,
} from "../../../components";
import { ArrowSvg } from "../../../assets/svg";
import { useTranslation } from "react-i18next";
import { BackButton } from "../components/BackButton/BackButton";
import {
  ClientUniqueCheckResp,
  useClientUniqueCheck,
} from "../../../service/client/useClientUniqueCheck";
import {
  AuthorizationModal,
  AuthSteps,
} from "../../AuthorizationModal/AuthorizationModal";
import {
  Hash,
  SendOtpCodeResp,
  useSendOtpCode,
} from "../../../service/otp/useSendOtpCode";
import { ClientData } from "../../../service/client/useGetClient";
import {
  SenderTypes,
  useCreateTask,
} from "../../../service/task/useCreateTask";
import { MainSteps } from "../SendTask";
import TagManager from "react-gtm-module";
import { useGetCities } from "../../../service/catalog/useGetCities";

interface ICommentStep {
  handleBack: () => void;
  form: FormInstance;
  setStep: (step: MainSteps) => void;
}

export const CommentStep: React.FC<ICommentStep> = ({
  form,
  handleBack,
  setStep,
}) => {
  const { t } = useTranslation();
  const [authModalStep, setAuthModalStep] = useState<AuthSteps | null>(null);
  const [hash, setHash] = useState<Hash>();

  const { mutate: clientUniqueCheck, isLoading: clientUniqueCheckLoading } =
    useClientUniqueCheck();
  const { mutate: sendOtp, isLoading: sendOtpLoading } = useSendOtpCode();
  const { mutate: createTask, isLoading: createTaskLoading } = useCreateTask();
  const { data: cities } = useGetCities();

  const onSendOtpSuccess = (data: SendOtpCodeResp) => {
    setHash(data.data.hash);
    setAuthModalStep(AuthSteps.OTP);
  };

  const onUniqueCheckSuccess = (data: ClientUniqueCheckResp) => {
    if (data.data.isPhoneNumberUnique) {
      //user does not exist, open personal data modal
      setAuthModalStep(AuthSteps.PERSONAL_INFO);
    } else {
      //user exists, open otp modal
      const phoneWithPrefix = `995${form.getFieldValue("phone")}`;

      sendOtp(
        {
          phoneNumber: phoneWithPrefix,
        },
        {
          onSuccess: onSendOtpSuccess,
        },
      );
    }
  };

  const onContactInfoStepNext = () => {
    form.validateFields().then(() => {
      const phoneWithPrefix = `995${form.getFieldValue("phone")}`;
      clientUniqueCheck(
        {
          phoneNumber: phoneWithPrefix,
        },
        {
          onSuccess: onUniqueCheckSuccess,
        },
      );
    });
  };

  const handleCreateTask = (
    client: ClientData,
    onTaskCreateSuccess?: () => void,
  ) => {
    //create task
    const fieldValues = form.getFieldsValue(true);

    createTask(
      {
        Title: t("task_from_web"),
        ClientId: client.id,
        Address: fieldValues?.address,
        CityId: fieldValues?.cityId,
        Description: fieldValues?.description,
        WemplerTaskSenderType: SenderTypes.Web,
        // Files: [],
      },
      {
        onSuccess: () => {
          onTaskCreateSuccess?.();
          TagManager.dataLayer({
            dataLayer: {
              event: "generate_lead",
              search_term: fieldValues?.description,
              city_name: cities?.find(
                (item) =>
                  item.id.toString() === fieldValues?.cityId?.toString(),
              )?.name,
            },
          });
          setStep(MainSteps.FINAL);
        },
        onError: () => message.error(t("error_text")),
      },
    );
  };

  return (
    <>
      <div>
        <SectionHeading
          className={"main-heading"}
          title={t("describe_task")}
          h1Heading
        />
        <div className={"main-input-text"}>{t("comment")}</div>
      </div>
      <div className={"with-validation"}>
        <Form.Item name={"comment"} className={"step-four-form-item"}>
          <CustomTextArea
            placeholder={t("add_info")}
            id="gtm-task-flow-comment-textarea"
            className={"main-textarea gtm-task-flow-comment-textarea"}
          />
        </Form.Item>
      </div>
      <AuthorizationModal
        open={!!authModalStep}
        handleCancel={() => setAuthModalStep(null)}
        startStep={authModalStep ?? AuthSteps.PERSONAL_INFO}
        phoneNumber={form.getFieldValue("phone")}
        hash={hash}
        handleUserLoginSuccess={handleCreateTask}
      />
      <div className={"step-two-buttons-wrapper"}>
        <BackButton
          onClick={handleBack}
          id={"gtm-task-flow-comment-back-button"}
          className={"gtm-task-flow-comment-back-button"}
        />
        <CustomButton
          suffixIcon={<ArrowSvg />}
          onClick={onContactInfoStepNext}
          loading={
            clientUniqueCheckLoading || sendOtpLoading || createTaskLoading
          }
          id={"gtm-task-flow-comment-next-button"}
          className={"gtm-task-flow-comment-next-button"}
        >
          {t("next")}
        </CustomButton>
      </div>
    </>
  );
};
