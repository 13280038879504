import React from "react";

export const AppStoreSvg = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="64" height="64" rx="16" fill="#D3DAE1" />
      <path
        d="M42 37.607C41.214 39.887 38.861 43.924 36.437 43.968C34.829 43.999 34.312 43.015 32.474 43.015C30.637 43.015 30.062 43.938 28.542 43.998C25.97 44.097 22 38.171 22 33.003C22 28.256 25.308 25.903 28.198 25.86C29.748 25.832 31.212 26.905 32.157 26.905C33.106 26.905 34.884 25.615 36.753 25.804C37.535 25.837 39.732 26.119 41.142 28.181C37.401 30.623 37.984 35.73 42 37.607ZM36.778 20C33.952 20.114 31.646 23.079 31.968 25.531C34.58 25.734 37.086 22.806 36.778 20Z"
        fill="#2C3642"
      />
    </svg>
  );
};
