import { apiClient } from "../apiClient";
import { useMutation } from "@tanstack/react-query";
import { GeneralResponseType } from "../types";

export type VerifyOtpCodeReq = {
  phoneNumber: string;
  hash: string;
  code: string;
};

type VerifyOtpCodeData = {
  isVerified: boolean;
};

export type VerifyOtpCodeResp = GeneralResponseType<VerifyOtpCodeData>;

export const verifyOtpCode = (
  data: VerifyOtpCodeReq,
): Promise<VerifyOtpCodeResp> =>
  apiClient.post("/otp/gosmsOtpVerify", data).then((resp) => resp.data);

export const useVerifyOtpCode = (
  onSuccess?: (data: VerifyOtpCodeResp) => void,
) => {
  const { isLoading, mutate } = useMutation(verifyOtpCode, {
    onSuccess,
  });

  return {
    isLoading,
    mutate,
  };
};
