import React from "react";

export const EditProfileSvg = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="pencil-02" clipPath="url(#clip0_5648_904)">
        <path
          id="Icon"
          d="M14.9998 1.66675L18.3332 5.00008M1.6665 18.3334L2.73017 14.4333C2.79957 14.1789 2.83426 14.0516 2.88753 13.933C2.93482 13.8276 2.99294 13.7275 3.06093 13.6342C3.13752 13.5291 3.23076 13.4358 3.41726 13.2493L12.0284 4.63815C12.1934 4.47315 12.2759 4.39064 12.3711 4.35973C12.4548 4.33254 12.5449 4.33254 12.6286 4.35973C12.7237 4.39064 12.8062 4.47315 12.9712 4.63815L15.3618 7.02868C15.5268 7.19368 15.6093 7.27619 15.6402 7.37132C15.6674 7.45501 15.6674 7.54515 15.6402 7.62884C15.6093 7.72398 15.5268 7.80648 15.3618 7.97149L6.75059 16.5827C6.5641 16.7692 6.47085 16.8624 6.36574 16.939C6.27241 17.007 6.17227 17.0651 6.06693 17.1124C5.94829 17.1657 5.82107 17.2004 5.56662 17.2697L1.6665 18.3334Z"
          stroke="#2C3642"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5648_904">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
