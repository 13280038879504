import React from "react";
import "./LanguageSelect.scss";
import { Select } from "antd";
import { LanguageItemType } from "../../configs/globalConfigs";

type Props = {
  options: LanguageItemType[];
  className?: string;
  [prop: string]: any;
};

const { Option } = Select;

export const LanguageSelect: React.FC<Props> = ({
  options,
  className,
  ...rest
}) => {
  return (
    <Select
      className={`language-select ${className || ""}`}
      popupClassName={"language-select-dropdown"}
      optionLabelProp={"label"}
      {...rest}
    >
      {options.map((item) => (
        <Option
          key={item.id}
          value={item.value}
          label={<div className={"language-option-content"}>{item.flag}</div>}
          className={"language-select-option"}
        >
          <div className={"language-option-content"}>
            {item.flag}
            <div>{item.label}</div>
          </div>
        </Option>
      ))}
    </Select>
  );
};
