import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations, { Languages } from "./resources";
import { getLocalStorageLanguage } from "../utils/helpers";

const lng = getLocalStorageLanguage() ?? Languages.KA;

const resources = {
  en: {
    translation: translations.en,
  },
  ka: {
    translation: translations.ka,
  },
  ru: {
    translation: translations.ru,
  },
};

const initI18N = (): void => {
  i18n.use(initReactI18next).init({
    resources,
    lng,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
};

export default initI18N;
