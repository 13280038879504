import React from "react";
import "./Stats.scss";
import { StatOneSvg, StatTwoSvg, StatThreeSvg } from "../../../assets/svg";
import { StatItem } from "../../../components";
import { useTranslation } from "react-i18next";

export const Stats = () => {
  const { t } = useTranslation();

  const statItems = [
    {
      id: 1,
      icon: <StatOneSvg />,
      count: 10000,
      subText: t("clients"),
      color: "#EF3653",
      withPlus: true,
    },
    {
      id: 2,
      icon: <StatTwoSvg />,
      count: 7000,
      subText: t("task_doers"),
      color: "#37a6f7",
      withPlus: true,
    },
    {
      id: 3,
      icon: <StatThreeSvg />,
      count: 20000,
      subText: t("task"),
      color: "#06d672",
      withPlus: true,
    },
  ];
  return (
    <div data-aos="fade-in" className={"stat-container"}>
      {statItems.map((item) => {
        return (
          <StatItem
            key={item.id}
            statIcon={item.icon}
            statNumber={item.count}
            statSubText={item.subText}
            color={item.color}
            withPlus={item?.withPlus}
          />
        );
      })}
    </div>
  );
};
